@import url(https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap);

/*!
 * Bootstrap Grid v5.0.2 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */


.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: .75rem;
  padding-right: var(--bs-gutter-x, .75rem);
  padding-left: .75rem;
  padding-left: var(--bs-gutter-x, .75rem);
  margin-right: auto;
  margin-left: auto
}

@media (min-width:576px) {

  .container,
  .container-sm {
    max-width: 540px
  }
}

@media (min-width:768px) {

  .container,
  .container-md,
  .container-sm {
    max-width: 720px
  }
}

@media (min-width:992px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px
  }
}

@media (min-width:1200px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1140px
  }
}

@media (min-width:1400px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1200px
  }
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y)*-1);
  margin-right: calc(var(--bs-gutter-x)*-0.5);
  margin-left: calc(var(--bs-gutter-x)*-0.5)
}

.row>* {
  box-sizing: border-box;
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x)*0.5);
  padding-left: calc(var(--bs-gutter-x)*0.5);
  margin-top: var(--bs-gutter-y)
}

.col {
  flex: 1 0
}

.row-cols-auto>* {
  flex: 0 0 auto;
  width: auto
}

.row-cols-1>* {
  flex: 0 0 auto;
  width: 100%
}

.row-cols-2>* {
  flex: 0 0 auto;
  width: 50%
}

.row-cols-3>* {
  flex: 0 0 auto;
  width: 33.3333333333%
}

.row-cols-4>* {
  flex: 0 0 auto;
  width: 25%
}

.row-cols-5>* {
  flex: 0 0 auto;
  width: 20%
}

.row-cols-6>* {
  flex: 0 0 auto;
  width: 16.6666666667%
}

@media (min-width:576px) {
  .col-sm {
    flex: 1 0
  }

  .row-cols-sm-auto>* {
    flex: 0 0 auto;
    width: auto
  }

  .row-cols-sm-1>* {
    flex: 0 0 auto;
    width: 100%
  }

  .row-cols-sm-2>* {
    flex: 0 0 auto;
    width: 50%
  }

  .row-cols-sm-3>* {
    flex: 0 0 auto;
    width: 33.3333333333%
  }

  .row-cols-sm-4>* {
    flex: 0 0 auto;
    width: 25%
  }

  .row-cols-sm-5>* {
    flex: 0 0 auto;
    width: 20%
  }

  .row-cols-sm-6>* {
    flex: 0 0 auto;
    width: 16.6666666667%
  }
}

@media (min-width:768px) {
  .col-md {
    flex: 1 0
  }

  .row-cols-md-auto>* {
    flex: 0 0 auto;
    width: auto
  }

  .row-cols-md-1>* {
    flex: 0 0 auto;
    width: 100%
  }

  .row-cols-md-2>* {
    flex: 0 0 auto;
    width: 50%
  }

  .row-cols-md-3>* {
    flex: 0 0 auto;
    width: 33.3333333333%
  }

  .row-cols-md-4>* {
    flex: 0 0 auto;
    width: 25%
  }

  .row-cols-md-5>* {
    flex: 0 0 auto;
    width: 20%
  }

  .row-cols-md-6>* {
    flex: 0 0 auto;
    width: 16.6666666667%
  }
}

@media (min-width:992px) {
  .col-lg {
    flex: 1 0
  }

  .row-cols-lg-auto>* {
    flex: 0 0 auto;
    width: auto
  }

  .row-cols-lg-1>* {
    flex: 0 0 auto;
    width: 100%
  }

  .row-cols-lg-2>* {
    flex: 0 0 auto;
    width: 50%
  }

  .row-cols-lg-3>* {
    flex: 0 0 auto;
    width: 33.3333333333%
  }

  .row-cols-lg-4>* {
    flex: 0 0 auto;
    width: 25%
  }

  .row-cols-lg-5>* {
    flex: 0 0 auto;
    width: 20%
  }

  .row-cols-lg-6>* {
    flex: 0 0 auto;
    width: 16.6666666667%
  }
}

@media (min-width:1200px) {
  .col-xl {
    flex: 1 0
  }

  .row-cols-xl-auto>* {
    flex: 0 0 auto;
    width: auto
  }

  .row-cols-xl-1>* {
    flex: 0 0 auto;
    width: 100%
  }

  .row-cols-xl-2>* {
    flex: 0 0 auto;
    width: 50%
  }

  .row-cols-xl-3>* {
    flex: 0 0 auto;
    width: 33.3333333333%
  }

  .row-cols-xl-4>* {
    flex: 0 0 auto;
    width: 25%
  }

  .row-cols-xl-5>* {
    flex: 0 0 auto;
    width: 20%
  }

  .row-cols-xl-6>* {
    flex: 0 0 auto;
    width: 16.6666666667%
  }
}

@media (min-width:1400px) {
  .col-xxl {
    flex: 1 0
  }

  .row-cols-xxl-auto>* {
    flex: 0 0 auto;
    width: auto
  }

  .row-cols-xxl-1>* {
    flex: 0 0 auto;
    width: 100%
  }

  .row-cols-xxl-2>* {
    flex: 0 0 auto;
    width: 50%
  }

  .row-cols-xxl-3>* {
    flex: 0 0 auto;
    width: 33.3333333333%
  }

  .row-cols-xxl-4>* {
    flex: 0 0 auto;
    width: 25%
  }

  .row-cols-xxl-5>* {
    flex: 0 0 auto;
    width: 20%
  }

  .row-cols-xxl-6>* {
    flex: 0 0 auto;
    width: 16.6666666667%
  }
}

.col-auto {
  flex: 0 0 auto;
  width: auto
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%
}

.col-3 {
  flex: 0 0 auto;
  width: 25%
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%
}

.col-6 {
  flex: 0 0 auto;
  width: 50%
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%
}

.col-9 {
  flex: 0 0 auto;
  width: 75%
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%
}

.col-12 {
  flex: 0 0 auto;
  width: 100%
}

.offset-1 {
  margin-left: 8.33333333%
}

.offset-2 {
  margin-left: 16.66666667%
}

.offset-3 {
  margin-left: 25%
}

.offset-4 {
  margin-left: 33.33333333%
}

.offset-5 {
  margin-left: 41.66666667%
}

.offset-6 {
  margin-left: 50%
}

.offset-7 {
  margin-left: 58.33333333%
}

.offset-8 {
  margin-left: 66.66666667%
}

.offset-9 {
  margin-left: 75%
}

.offset-10 {
  margin-left: 83.33333333%
}

.offset-11 {
  margin-left: 91.66666667%
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem
}

@media (min-width:576px) {
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%
  }

  .offset-sm-0 {
    margin-left: 0
  }

  .offset-sm-1 {
    margin-left: 8.33333333%
  }

  .offset-sm-2 {
    margin-left: 16.66666667%
  }

  .offset-sm-3 {
    margin-left: 25%
  }

  .offset-sm-4 {
    margin-left: 33.33333333%
  }

  .offset-sm-5 {
    margin-left: 41.66666667%
  }

  .offset-sm-6 {
    margin-left: 50%
  }

  .offset-sm-7 {
    margin-left: 58.33333333%
  }

  .offset-sm-8 {
    margin-left: 66.66666667%
  }

  .offset-sm-9 {
    margin-left: 75%
  }

  .offset-sm-10 {
    margin-left: 83.33333333%
  }

  .offset-sm-11 {
    margin-left: 91.66666667%
  }

  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0
  }

  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0
  }

  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem
  }

  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem
  }

  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem
  }

  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem
  }

  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem
  }

  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem
  }

  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem
  }

  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem
  }

  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem
  }

  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem
  }
}

@media (min-width:768px) {
  .col-md-auto {
    flex: 0 0 auto;
    width: auto
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%
  }

  .offset-md-0 {
    margin-left: 0
  }

  .offset-md-1 {
    margin-left: 8.33333333%
  }

  .offset-md-2 {
    margin-left: 16.66666667%
  }

  .offset-md-3 {
    margin-left: 25%
  }

  .offset-md-4 {
    margin-left: 33.33333333%
  }

  .offset-md-5 {
    margin-left: 41.66666667%
  }

  .offset-md-6 {
    margin-left: 50%
  }

  .offset-md-7 {
    margin-left: 58.33333333%
  }

  .offset-md-8 {
    margin-left: 66.66666667%
  }

  .offset-md-9 {
    margin-left: 75%
  }

  .offset-md-10 {
    margin-left: 83.33333333%
  }

  .offset-md-11 {
    margin-left: 91.66666667%
  }

  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0
  }

  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0
  }

  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem
  }

  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem
  }

  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem
  }

  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem
  }

  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem
  }

  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem
  }

  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem
  }

  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem
  }

  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem
  }

  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem
  }
}

@media (min-width:992px) {
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%
  }

  .offset-lg-0 {
    margin-left: 0
  }

  .offset-lg-1 {
    margin-left: 8.33333333%
  }

  .offset-lg-2 {
    margin-left: 16.66666667%
  }

  .offset-lg-3 {
    margin-left: 25%
  }

  .offset-lg-4 {
    margin-left: 33.33333333%
  }

  .offset-lg-5 {
    margin-left: 41.66666667%
  }

  .offset-lg-6 {
    margin-left: 50%
  }

  .offset-lg-7 {
    margin-left: 58.33333333%
  }

  .offset-lg-8 {
    margin-left: 66.66666667%
  }

  .offset-lg-9 {
    margin-left: 75%
  }

  .offset-lg-10 {
    margin-left: 83.33333333%
  }

  .offset-lg-11 {
    margin-left: 91.66666667%
  }

  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0
  }

  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0
  }

  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem
  }

  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem
  }

  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem
  }

  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem
  }

  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem
  }

  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem
  }

  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem
  }

  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem
  }

  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem
  }

  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem
  }
}

@media (min-width:1200px) {
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%
  }

  .offset-xl-0 {
    margin-left: 0
  }

  .offset-xl-1 {
    margin-left: 8.33333333%
  }

  .offset-xl-2 {
    margin-left: 16.66666667%
  }

  .offset-xl-3 {
    margin-left: 25%
  }

  .offset-xl-4 {
    margin-left: 33.33333333%
  }

  .offset-xl-5 {
    margin-left: 41.66666667%
  }

  .offset-xl-6 {
    margin-left: 50%
  }

  .offset-xl-7 {
    margin-left: 58.33333333%
  }

  .offset-xl-8 {
    margin-left: 66.66666667%
  }

  .offset-xl-9 {
    margin-left: 75%
  }

  .offset-xl-10 {
    margin-left: 83.33333333%
  }

  .offset-xl-11 {
    margin-left: 91.66666667%
  }

  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0
  }

  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0
  }

  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem
  }

  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem
  }

  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem
  }

  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem
  }

  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem
  }

  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem
  }

  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem
  }

  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem
  }

  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem
  }

  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem
  }
}

@media (min-width:1400px) {
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto
  }

  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%
  }

  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%
  }

  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%
  }

  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%
  }

  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%
  }

  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%
  }

  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%
  }

  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%
  }

  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%
  }

  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%
  }

  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%
  }

  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%
  }

  .offset-xxl-0 {
    margin-left: 0
  }

  .offset-xxl-1 {
    margin-left: 8.33333333%
  }

  .offset-xxl-2 {
    margin-left: 16.66666667%
  }

  .offset-xxl-3 {
    margin-left: 25%
  }

  .offset-xxl-4 {
    margin-left: 33.33333333%
  }

  .offset-xxl-5 {
    margin-left: 41.66666667%
  }

  .offset-xxl-6 {
    margin-left: 50%
  }

  .offset-xxl-7 {
    margin-left: 58.33333333%
  }

  .offset-xxl-8 {
    margin-left: 66.66666667%
  }

  .offset-xxl-9 {
    margin-left: 75%
  }

  .offset-xxl-10 {
    margin-left: 83.33333333%
  }

  .offset-xxl-11 {
    margin-left: 91.66666667%
  }

  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0
  }

  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0
  }

  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem
  }

  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem
  }

  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem
  }

  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem
  }

  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem
  }

  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem
  }

  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem
  }

  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem
  }

  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem
  }

  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem
  }
}

.d-inline {
  display: inline !important
}

.d-inline-block {
  display: inline-block !important
}

.d-block {
  display: block !important
}

.d-grid {
  display: grid !important
}

.d-table {
  display: table !important
}

.d-table-row {
  display: table-row !important
}

.d-table-cell {
  display: table-cell !important
}

.d-flex {
  display: flex !important
}

.d-inline-flex {
  display: inline-flex !important
}

.d-none {
  display: none !important
}

.flex-fill {
  flex: 1 1 auto !important
}

.flex-row {
  flex-direction: row !important
}

.flex-column {
  flex-direction: column !important
}

.flex-row-reverse {
  flex-direction: row-reverse !important
}

.flex-column-reverse {
  flex-direction: column-reverse !important
}

.flex-grow-0 {
  flex-grow: 0 !important
}

.flex-grow-1 {
  flex-grow: 1 !important
}

.flex-shrink-0 {
  flex-shrink: 0 !important
}

.flex-shrink-1 {
  flex-shrink: 1 !important
}

.flex-wrap {
  flex-wrap: wrap !important
}

.flex-nowrap {
  flex-wrap: nowrap !important
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important
}

.justify-content-start {
  justify-content: flex-start !important
}

.justify-content-end {
  justify-content: flex-end !important
}

.justify-content-center {
  justify-content: center !important
}

.justify-content-between {
  justify-content: space-between !important
}

.justify-content-around {
  justify-content: space-around !important
}

.justify-content-evenly {
  justify-content: space-evenly !important
}

.align-items-start {
  align-items: flex-start !important
}

.align-items-end {
  align-items: flex-end !important
}

.align-items-center {
  align-items: center !important
}

.align-items-baseline {
  align-items: baseline !important
}

.align-items-stretch {
  align-items: stretch !important
}

.align-content-start {
  align-content: flex-start !important
}

.align-content-end {
  align-content: flex-end !important
}

.align-content-center {
  align-content: center !important
}

.align-content-between {
  align-content: space-between !important
}

.align-content-around {
  align-content: space-around !important
}

.align-content-stretch {
  align-content: stretch !important
}

.align-self-auto {
  align-self: auto !important
}

.align-self-start {
  align-self: flex-start !important
}

.align-self-end {
  align-self: flex-end !important
}

.align-self-center {
  align-self: center !important
}

.align-self-baseline {
  align-self: baseline !important
}

.align-self-stretch {
  align-self: stretch !important
}

.order-first {
  order: -1 !important
}

.order-0 {
  order: 0 !important
}

.order-1 {
  order: 1 !important
}

.order-2 {
  order: 2 !important
}

.order-3 {
  order: 3 !important
}

.order-4 {
  order: 4 !important
}

.order-5 {
  order: 5 !important
}

.order-last {
  order: 6 !important
}

.m-0 {
  margin: 0 !important
}

.m-1 {
  margin: .25rem !important
}

.m-2 {
  margin: .5rem !important
}

.m-3 {
  margin: 1rem !important
}

.m-4 {
  margin: 1.5rem !important
}

.m-5 {
  margin: 3rem !important
}

.m-auto {
  margin: auto !important
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important
}

.mx-1 {
  margin-right: .25rem !important;
  margin-left: .25rem !important
}

.mx-2 {
  margin-right: .5rem !important;
  margin-left: .5rem !important
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important
}

.my-1 {
  margin-top: .25rem !important;
  margin-bottom: .25rem !important
}

.my-2 {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important
}

.mt-0 {
  margin-top: 0 !important
}

.mt-1 {
  margin-top: .25rem !important
}

.mt-2 {
  margin-top: .5rem !important
}

.mt-3 {
  margin-top: 1rem !important
}

.mt-4 {
  margin-top: 1.5rem !important
}

.mt-5 {
  margin-top: 3rem !important
}

.mt-auto {
  margin-top: auto !important
}

.me-0 {
  margin-right: 0 !important
}

.me-1 {
  margin-right: .25rem !important
}

.me-2 {
  margin-right: .5rem !important
}

.me-3 {
  margin-right: 1rem !important
}

.me-4 {
  margin-right: 1.5rem !important
}

.me-5 {
  margin-right: 3rem !important
}

.mb-0 {
  margin-bottom: 0 !important
}

.mb-1 {
  margin-bottom: .25rem !important
}

.mb-2 {
  margin-bottom: .5rem !important
}

.mb-3 {
  margin-bottom: 1rem !important
}

.mb-4 {
  margin-bottom: 1.5rem !important
}

.mb-5 {
  margin-bottom: 3rem !important
}

.mb-auto {
  margin-bottom: auto !important
}

.ms-0 {
  margin-left: 0 !important
}

.ms-1 {
  margin-left: .25rem !important
}

.ms-2 {
  margin-left: .5rem !important
}

.ms-3 {
  margin-left: 1rem !important
}

.ms-4 {
  margin-left: 1.5rem !important
}

.ms-5 {
  margin-left: 3rem !important
}

.ms-auto {
  margin-left: auto !important
}

.p-0 {
  padding: 0 !important
}

.p-1 {
  padding: .25rem !important
}

.p-2 {
  padding: .5rem !important
}

.p-3 {
  padding: 1rem !important
}

.p-4 {
  padding: 1.5rem !important
}

.p-5 {
  padding: 3rem !important
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important
}

.px-1 {
  padding-right: .25rem !important;
  padding-left: .25rem !important
}

.px-2 {
  padding-right: .5rem !important;
  padding-left: .5rem !important
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important
}

.py-1 {
  padding-top: .25rem !important;
  padding-bottom: .25rem !important
}

.py-2 {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important
}

.pt-0 {
  padding-top: 0 !important
}

.pt-1 {
  padding-top: .25rem !important
}

.pt-2 {
  padding-top: .5rem !important
}

.pt-3 {
  padding-top: 1rem !important
}

.pt-4 {
  padding-top: 1.5rem !important
}

.pt-5 {
  padding-top: 3rem !important
}

.pe-0 {
  padding-right: 0 !important
}

.pe-1 {
  padding-right: .25rem !important
}

.pe-2 {
  padding-right: .5rem !important
}

.pe-3 {
  padding-right: 1rem !important
}

.pe-4 {
  padding-right: 1.5rem !important
}

.pe-5 {
  padding-right: 3rem !important
}

.pb-0 {
  padding-bottom: 0 !important
}

.pb-1 {
  padding-bottom: .25rem !important
}

.pb-2 {
  padding-bottom: .5rem !important
}

.pb-3 {
  padding-bottom: 1rem !important
}

.pb-4 {
  padding-bottom: 1.5rem !important
}

.pb-5 {
  padding-bottom: 3rem !important
}

.ps-0 {
  padding-left: 0 !important
}

.ps-1 {
  padding-left: .25rem !important
}

.ps-2 {
  padding-left: .5rem !important
}

.ps-3 {
  padding-left: 1rem !important
}

.ps-4 {
  padding-left: 1.5rem !important
}

.ps-5 {
  padding-left: 3rem !important
}

@media (min-width:576px) {
  .d-sm-inline {
    display: inline !important
  }

  .d-sm-inline-block {
    display: inline-block !important
  }

  .d-sm-block {
    display: block !important
  }

  .d-sm-grid {
    display: grid !important
  }

  .d-sm-table {
    display: table !important
  }

  .d-sm-table-row {
    display: table-row !important
  }

  .d-sm-table-cell {
    display: table-cell !important
  }

  .d-sm-flex {
    display: flex !important
  }

  .d-sm-inline-flex {
    display: inline-flex !important
  }

  .d-sm-none {
    display: none !important
  }

  .flex-sm-fill {
    flex: 1 1 auto !important
  }

  .flex-sm-row {
    flex-direction: row !important
  }

  .flex-sm-column {
    flex-direction: column !important
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important
  }

  .justify-content-sm-start {
    justify-content: flex-start !important
  }

  .justify-content-sm-end {
    justify-content: flex-end !important
  }

  .justify-content-sm-center {
    justify-content: center !important
  }

  .justify-content-sm-between {
    justify-content: space-between !important
  }

  .justify-content-sm-around {
    justify-content: space-around !important
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important
  }

  .align-items-sm-start {
    align-items: flex-start !important
  }

  .align-items-sm-end {
    align-items: flex-end !important
  }

  .align-items-sm-center {
    align-items: center !important
  }

  .align-items-sm-baseline {
    align-items: baseline !important
  }

  .align-items-sm-stretch {
    align-items: stretch !important
  }

  .align-content-sm-start {
    align-content: flex-start !important
  }

  .align-content-sm-end {
    align-content: flex-end !important
  }

  .align-content-sm-center {
    align-content: center !important
  }

  .align-content-sm-between {
    align-content: space-between !important
  }

  .align-content-sm-around {
    align-content: space-around !important
  }

  .align-content-sm-stretch {
    align-content: stretch !important
  }

  .align-self-sm-auto {
    align-self: auto !important
  }

  .align-self-sm-start {
    align-self: flex-start !important
  }

  .align-self-sm-end {
    align-self: flex-end !important
  }

  .align-self-sm-center {
    align-self: center !important
  }

  .align-self-sm-baseline {
    align-self: baseline !important
  }

  .align-self-sm-stretch {
    align-self: stretch !important
  }

  .order-sm-first {
    order: -1 !important
  }

  .order-sm-0 {
    order: 0 !important
  }

  .order-sm-1 {
    order: 1 !important
  }

  .order-sm-2 {
    order: 2 !important
  }

  .order-sm-3 {
    order: 3 !important
  }

  .order-sm-4 {
    order: 4 !important
  }

  .order-sm-5 {
    order: 5 !important
  }

  .order-sm-last {
    order: 6 !important
  }

  .m-sm-0 {
    margin: 0 !important
  }

  .m-sm-1 {
    margin: .25rem !important
  }

  .m-sm-2 {
    margin: .5rem !important
  }

  .m-sm-3 {
    margin: 1rem !important
  }

  .m-sm-4 {
    margin: 1.5rem !important
  }

  .m-sm-5 {
    margin: 3rem !important
  }

  .m-sm-auto {
    margin: auto !important
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important
  }

  .mx-sm-1 {
    margin-right: .25rem !important;
    margin-left: .25rem !important
  }

  .mx-sm-2 {
    margin-right: .5rem !important;
    margin-left: .5rem !important
  }

  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important
  }

  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important
  }

  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important
  }

  .my-sm-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important
  }

  .my-sm-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important
  }

  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important
  }

  .mt-sm-0 {
    margin-top: 0 !important
  }

  .mt-sm-1 {
    margin-top: .25rem !important
  }

  .mt-sm-2 {
    margin-top: .5rem !important
  }

  .mt-sm-3 {
    margin-top: 1rem !important
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important
  }

  .mt-sm-5 {
    margin-top: 3rem !important
  }

  .mt-sm-auto {
    margin-top: auto !important
  }

  .me-sm-0 {
    margin-right: 0 !important
  }

  .me-sm-1 {
    margin-right: .25rem !important
  }

  .me-sm-2 {
    margin-right: .5rem !important
  }

  .me-sm-3 {
    margin-right: 1rem !important
  }

  .me-sm-4 {
    margin-right: 1.5rem !important
  }

  .me-sm-5 {
    margin-right: 3rem !important
  }

  .me-sm-auto {
    margin-right: auto !important
  }

  .mb-sm-0 {
    margin-bottom: 0 !important
  }

  .mb-sm-1 {
    margin-bottom: .25rem !important
  }

  .mb-sm-2 {
    margin-bottom: .5rem !important
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important
  }

  .mb-sm-5 {
    margin-bottom: 3rem !important
  }

  .mb-sm-auto {
    margin-bottom: auto !important
  }

  .ms-sm-0 {
    margin-left: 0 !important
  }

  .ms-sm-1 {
    margin-left: .25rem !important
  }

  .ms-sm-2 {
    margin-left: .5rem !important
  }

  .ms-sm-3 {
    margin-left: 1rem !important
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important
  }

  .ms-sm-5 {
    margin-left: 3rem !important
  }

  .ms-sm-auto {
    margin-left: auto !important
  }

  .p-sm-0 {
    padding: 0 !important
  }

  .p-sm-1 {
    padding: .25rem !important
  }

  .p-sm-2 {
    padding: .5rem !important
  }

  .p-sm-3 {
    padding: 1rem !important
  }

  .p-sm-4 {
    padding: 1.5rem !important
  }

  .p-sm-5 {
    padding: 3rem !important
  }

  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important
  }

  .px-sm-1 {
    padding-right: .25rem !important;
    padding-left: .25rem !important
  }

  .px-sm-2 {
    padding-right: .5rem !important;
    padding-left: .5rem !important
  }

  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important
  }

  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important
  }

  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important
  }

  .py-sm-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important
  }

  .py-sm-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important
  }

  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important
  }

  .pt-sm-0 {
    padding-top: 0 !important
  }

  .pt-sm-1 {
    padding-top: .25rem !important
  }

  .pt-sm-2 {
    padding-top: .5rem !important
  }

  .pt-sm-3 {
    padding-top: 1rem !important
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important
  }

  .pt-sm-5 {
    padding-top: 3rem !important
  }

  .pe-sm-0 {
    padding-right: 0 !important
  }

  .pe-sm-1 {
    padding-right: .25rem !important
  }

  .pe-sm-2 {
    padding-right: .5rem !important
  }

  .pe-sm-3 {
    padding-right: 1rem !important
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important
  }

  .pe-sm-5 {
    padding-right: 3rem !important
  }

  .pb-sm-0 {
    padding-bottom: 0 !important
  }

  .pb-sm-1 {
    padding-bottom: .25rem !important
  }

  .pb-sm-2 {
    padding-bottom: .5rem !important
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important
  }

  .pb-sm-5 {
    padding-bottom: 3rem !important
  }

  .ps-sm-0 {
    padding-left: 0 !important
  }

  .ps-sm-1 {
    padding-left: .25rem !important
  }

  .ps-sm-2 {
    padding-left: .5rem !important
  }

  .ps-sm-3 {
    padding-left: 1rem !important
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important
  }

  .ps-sm-5 {
    padding-left: 3rem !important
  }
}

@media (min-width:768px) {
  .d-md-inline {
    display: inline !important
  }

  .d-md-inline-block {
    display: inline-block !important
  }

  .d-md-block {
    display: block !important
  }

  .d-md-grid {
    display: grid !important
  }

  .d-md-table {
    display: table !important
  }

  .d-md-table-row {
    display: table-row !important
  }

  .d-md-table-cell {
    display: table-cell !important
  }

  .d-md-flex {
    display: flex !important
  }

  .d-md-inline-flex {
    display: inline-flex !important
  }

  .d-md-none {
    display: none !important
  }

  .flex-md-fill {
    flex: 1 1 auto !important
  }

  .flex-md-row {
    flex-direction: row !important
  }

  .flex-md-column {
    flex-direction: column !important
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important
  }

  .flex-md-wrap {
    flex-wrap: wrap !important
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important
  }

  .justify-content-md-start {
    justify-content: flex-start !important
  }

  .justify-content-md-end {
    justify-content: flex-end !important
  }

  .justify-content-md-center {
    justify-content: center !important
  }

  .justify-content-md-between {
    justify-content: space-between !important
  }

  .justify-content-md-around {
    justify-content: space-around !important
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important
  }

  .align-items-md-start {
    align-items: flex-start !important
  }

  .align-items-md-end {
    align-items: flex-end !important
  }

  .align-items-md-center {
    align-items: center !important
  }

  .align-items-md-baseline {
    align-items: baseline !important
  }

  .align-items-md-stretch {
    align-items: stretch !important
  }

  .align-content-md-start {
    align-content: flex-start !important
  }

  .align-content-md-end {
    align-content: flex-end !important
  }

  .align-content-md-center {
    align-content: center !important
  }

  .align-content-md-between {
    align-content: space-between !important
  }

  .align-content-md-around {
    align-content: space-around !important
  }

  .align-content-md-stretch {
    align-content: stretch !important
  }

  .align-self-md-auto {
    align-self: auto !important
  }

  .align-self-md-start {
    align-self: flex-start !important
  }

  .align-self-md-end {
    align-self: flex-end !important
  }

  .align-self-md-center {
    align-self: center !important
  }

  .align-self-md-baseline {
    align-self: baseline !important
  }

  .align-self-md-stretch {
    align-self: stretch !important
  }

  .order-md-first {
    order: -1 !important
  }

  .order-md-0 {
    order: 0 !important
  }

  .order-md-1 {
    order: 1 !important
  }

  .order-md-2 {
    order: 2 !important
  }

  .order-md-3 {
    order: 3 !important
  }

  .order-md-4 {
    order: 4 !important
  }

  .order-md-5 {
    order: 5 !important
  }

  .order-md-last {
    order: 6 !important
  }

  .m-md-0 {
    margin: 0 !important
  }

  .m-md-1 {
    margin: .25rem !important
  }

  .m-md-2 {
    margin: .5rem !important
  }

  .m-md-3 {
    margin: 1rem !important
  }

  .m-md-4 {
    margin: 1.5rem !important
  }

  .m-md-5 {
    margin: 3rem !important
  }

  .m-md-auto {
    margin: auto !important
  }

  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important
  }

  .mx-md-1 {
    margin-right: .25rem !important;
    margin-left: .25rem !important
  }

  .mx-md-2 {
    margin-right: .5rem !important;
    margin-left: .5rem !important
  }

  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important
  }

  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important
  }

  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important
  }

  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important
  }

  .my-md-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important
  }

  .my-md-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important
  }

  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important
  }

  .mt-md-0 {
    margin-top: 0 !important
  }

  .mt-md-1 {
    margin-top: .25rem !important
  }

  .mt-md-2 {
    margin-top: .5rem !important
  }

  .mt-md-3 {
    margin-top: 1rem !important
  }

  .mt-md-4 {
    margin-top: 1.5rem !important
  }

  .mt-md-5 {
    margin-top: 3rem !important
  }

  .mt-md-auto {
    margin-top: auto !important
  }

  .me-md-0 {
    margin-right: 0 !important
  }

  .me-md-1 {
    margin-right: .25rem !important
  }

  .me-md-2 {
    margin-right: .5rem !important
  }

  .me-md-3 {
    margin-right: 1rem !important
  }

  .me-md-4 {
    margin-right: 1.5rem !important
  }

  .me-md-5 {
    margin-right: 3rem !important
  }

  .me-md-auto {
    margin-right: auto !important
  }

  .mb-md-0 {
    margin-bottom: 0 !important
  }

  .mb-md-1 {
    margin-bottom: .25rem !important
  }

  .mb-md-2 {
    margin-bottom: .5rem !important
  }

  .mb-md-3 {
    margin-bottom: 1rem !important
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important
  }

  .mb-md-5 {
    margin-bottom: 3rem !important
  }

  .mb-md-auto {
    margin-bottom: auto !important
  }

  .ms-md-0 {
    margin-left: 0 !important
  }

  .ms-md-1 {
    margin-left: .25rem !important
  }

  .ms-md-2 {
    margin-left: .5rem !important
  }

  .ms-md-3 {
    margin-left: 1rem !important
  }

  .ms-md-4 {
    margin-left: 1.5rem !important
  }

  .ms-md-5 {
    margin-left: 3rem !important
  }

  .ms-md-auto {
    margin-left: auto !important
  }

  .p-md-0 {
    padding: 0 !important
  }

  .p-md-1 {
    padding: .25rem !important
  }

  .p-md-2 {
    padding: .5rem !important
  }

  .p-md-3 {
    padding: 1rem !important
  }

  .p-md-4 {
    padding: 1.5rem !important
  }

  .p-md-5 {
    padding: 3rem !important
  }

  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important
  }

  .px-md-1 {
    padding-right: .25rem !important;
    padding-left: .25rem !important
  }

  .px-md-2 {
    padding-right: .5rem !important;
    padding-left: .5rem !important
  }

  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important
  }

  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important
  }

  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important
  }

  .py-md-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important
  }

  .py-md-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important
  }

  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important
  }

  .pt-md-0 {
    padding-top: 0 !important
  }

  .pt-md-1 {
    padding-top: .25rem !important
  }

  .pt-md-2 {
    padding-top: .5rem !important
  }

  .pt-md-3 {
    padding-top: 1rem !important
  }

  .pt-md-4 {
    padding-top: 1.5rem !important
  }

  .pt-md-5 {
    padding-top: 3rem !important
  }

  .pe-md-0 {
    padding-right: 0 !important
  }

  .pe-md-1 {
    padding-right: .25rem !important
  }

  .pe-md-2 {
    padding-right: .5rem !important
  }

  .pe-md-3 {
    padding-right: 1rem !important
  }

  .pe-md-4 {
    padding-right: 1.5rem !important
  }

  .pe-md-5 {
    padding-right: 3rem !important
  }

  .pb-md-0 {
    padding-bottom: 0 !important
  }

  .pb-md-1 {
    padding-bottom: .25rem !important
  }

  .pb-md-2 {
    padding-bottom: .5rem !important
  }

  .pb-md-3 {
    padding-bottom: 1rem !important
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important
  }

  .pb-md-5 {
    padding-bottom: 3rem !important
  }

  .ps-md-0 {
    padding-left: 0 !important
  }

  .ps-md-1 {
    padding-left: .25rem !important
  }

  .ps-md-2 {
    padding-left: .5rem !important
  }

  .ps-md-3 {
    padding-left: 1rem !important
  }

  .ps-md-4 {
    padding-left: 1.5rem !important
  }

  .ps-md-5 {
    padding-left: 3rem !important
  }
}

@media (min-width:992px) {
  .d-lg-inline {
    display: inline !important
  }

  .d-lg-inline-block {
    display: inline-block !important
  }

  .d-lg-block {
    display: block !important
  }

  .d-lg-grid {
    display: grid !important
  }

  .d-lg-table {
    display: table !important
  }

  .d-lg-table-row {
    display: table-row !important
  }

  .d-lg-table-cell {
    display: table-cell !important
  }

  .d-lg-flex {
    display: flex !important
  }

  .d-lg-inline-flex {
    display: inline-flex !important
  }

  .d-lg-none {
    display: none !important
  }

  .flex-lg-fill {
    flex: 1 1 auto !important
  }

  .flex-lg-row {
    flex-direction: row !important
  }

  .flex-lg-column {
    flex-direction: column !important
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important
  }

  .justify-content-lg-start {
    justify-content: flex-start !important
  }

  .justify-content-lg-end {
    justify-content: flex-end !important
  }

  .justify-content-lg-center {
    justify-content: center !important
  }

  .justify-content-lg-between {
    justify-content: space-between !important
  }

  .justify-content-lg-around {
    justify-content: space-around !important
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important
  }

  .align-items-lg-start {
    align-items: flex-start !important
  }

  .align-items-lg-end {
    align-items: flex-end !important
  }

  .align-items-lg-center {
    align-items: center !important
  }

  .align-items-lg-baseline {
    align-items: baseline !important
  }

  .align-items-lg-stretch {
    align-items: stretch !important
  }

  .align-content-lg-start {
    align-content: flex-start !important
  }

  .align-content-lg-end {
    align-content: flex-end !important
  }

  .align-content-lg-center {
    align-content: center !important
  }

  .align-content-lg-between {
    align-content: space-between !important
  }

  .align-content-lg-around {
    align-content: space-around !important
  }

  .align-content-lg-stretch {
    align-content: stretch !important
  }

  .align-self-lg-auto {
    align-self: auto !important
  }

  .align-self-lg-start {
    align-self: flex-start !important
  }

  .align-self-lg-end {
    align-self: flex-end !important
  }

  .align-self-lg-center {
    align-self: center !important
  }

  .align-self-lg-baseline {
    align-self: baseline !important
  }

  .align-self-lg-stretch {
    align-self: stretch !important
  }

  .order-lg-first {
    order: -1 !important
  }

  .order-lg-0 {
    order: 0 !important
  }

  .order-lg-1 {
    order: 1 !important
  }

  .order-lg-2 {
    order: 2 !important
  }

  .order-lg-3 {
    order: 3 !important
  }

  .order-lg-4 {
    order: 4 !important
  }

  .order-lg-5 {
    order: 5 !important
  }

  .order-lg-last {
    order: 6 !important
  }

  .m-lg-0 {
    margin: 0 !important
  }

  .m-lg-1 {
    margin: .25rem !important
  }

  .m-lg-2 {
    margin: .5rem !important
  }

  .m-lg-3 {
    margin: 1rem !important
  }

  .m-lg-4 {
    margin: 1.5rem !important
  }

  .m-lg-5 {
    margin: 3rem !important
  }

  .m-lg-auto {
    margin: auto !important
  }

  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important
  }

  .mx-lg-1 {
    margin-right: .25rem !important;
    margin-left: .25rem !important
  }

  .mx-lg-2 {
    margin-right: .5rem !important;
    margin-left: .5rem !important
  }

  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important
  }

  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important
  }

  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important
  }

  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important
  }

  .my-lg-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important
  }

  .my-lg-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important
  }

  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important
  }

  .mt-lg-0 {
    margin-top: 0 !important
  }

  .mt-lg-1 {
    margin-top: .25rem !important
  }

  .mt-lg-2 {
    margin-top: .5rem !important
  }

  .mt-lg-3 {
    margin-top: 1rem !important
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important
  }

  .mt-lg-5 {
    margin-top: 3rem !important
  }

  .mt-lg-auto {
    margin-top: auto !important
  }

  .me-lg-0 {
    margin-right: 0 !important
  }

  .me-lg-1 {
    margin-right: .25rem !important
  }

  .me-lg-2 {
    margin-right: .5rem !important
  }

  .me-lg-3 {
    margin-right: 1rem !important
  }

  .me-lg-4 {
    margin-right: 1.5rem !important
  }

  .me-lg-5 {
    margin-right: 3rem !important
  }

  .me-lg-auto {
    margin-right: auto !important
  }

  .mb-lg-0 {
    margin-bottom: 0 !important
  }

  .mb-lg-1 {
    margin-bottom: .25rem !important
  }

  .mb-lg-2 {
    margin-bottom: .5rem !important
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important
  }

  .mb-lg-5 {
    margin-bottom: 3rem !important
  }

  .mb-lg-auto {
    margin-bottom: auto !important
  }

  .ms-lg-0 {
    margin-left: 0 !important
  }

  .ms-lg-1 {
    margin-left: .25rem !important
  }

  .ms-lg-2 {
    margin-left: .5rem !important
  }

  .ms-lg-3 {
    margin-left: 1rem !important
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important
  }

  .ms-lg-5 {
    margin-left: 3rem !important
  }

  .ms-lg-auto {
    margin-left: auto !important
  }

  .p-lg-0 {
    padding: 0 !important
  }

  .p-lg-1 {
    padding: .25rem !important
  }

  .p-lg-2 {
    padding: .5rem !important
  }

  .p-lg-3 {
    padding: 1rem !important
  }

  .p-lg-4 {
    padding: 1.5rem !important
  }

  .p-lg-5 {
    padding: 3rem !important
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important
  }

  .px-lg-1 {
    padding-right: .25rem !important;
    padding-left: .25rem !important
  }

  .px-lg-2 {
    padding-right: .5rem !important;
    padding-left: .5rem !important
  }

  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important
  }

  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important
  }

  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important
  }

  .py-lg-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important
  }

  .py-lg-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important
  }

  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important
  }

  .pt-lg-0 {
    padding-top: 0 !important
  }

  .pt-lg-1 {
    padding-top: .25rem !important
  }

  .pt-lg-2 {
    padding-top: .5rem !important
  }

  .pt-lg-3 {
    padding-top: 1rem !important
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important
  }

  .pt-lg-5 {
    padding-top: 3rem !important
  }

  .pe-lg-0 {
    padding-right: 0 !important
  }

  .pe-lg-1 {
    padding-right: .25rem !important
  }

  .pe-lg-2 {
    padding-right: .5rem !important
  }

  .pe-lg-3 {
    padding-right: 1rem !important
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important
  }

  .pe-lg-5 {
    padding-right: 3rem !important
  }

  .pb-lg-0 {
    padding-bottom: 0 !important
  }

  .pb-lg-1 {
    padding-bottom: .25rem !important
  }

  .pb-lg-2 {
    padding-bottom: .5rem !important
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important
  }

  .pb-lg-5 {
    padding-bottom: 3rem !important
  }

  .ps-lg-0 {
    padding-left: 0 !important
  }

  .ps-lg-1 {
    padding-left: .25rem !important
  }

  .ps-lg-2 {
    padding-left: .5rem !important
  }

  .ps-lg-3 {
    padding-left: 1rem !important
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important
  }

  .ps-lg-5 {
    padding-left: 3rem !important
  }
}

@media (min-width:1200px) {
  .d-xl-inline {
    display: inline !important
  }

  .d-xl-inline-block {
    display: inline-block !important
  }

  .d-xl-block {
    display: block !important
  }

  .d-xl-grid {
    display: grid !important
  }

  .d-xl-table {
    display: table !important
  }

  .d-xl-table-row {
    display: table-row !important
  }

  .d-xl-table-cell {
    display: table-cell !important
  }

  .d-xl-flex {
    display: flex !important
  }

  .d-xl-inline-flex {
    display: inline-flex !important
  }

  .d-xl-none {
    display: none !important
  }

  .flex-xl-fill {
    flex: 1 1 auto !important
  }

  .flex-xl-row {
    flex-direction: row !important
  }

  .flex-xl-column {
    flex-direction: column !important
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important
  }

  .justify-content-xl-start {
    justify-content: flex-start !important
  }

  .justify-content-xl-end {
    justify-content: flex-end !important
  }

  .justify-content-xl-center {
    justify-content: center !important
  }

  .justify-content-xl-between {
    justify-content: space-between !important
  }

  .justify-content-xl-around {
    justify-content: space-around !important
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important
  }

  .align-items-xl-start {
    align-items: flex-start !important
  }

  .align-items-xl-end {
    align-items: flex-end !important
  }

  .align-items-xl-center {
    align-items: center !important
  }

  .align-items-xl-baseline {
    align-items: baseline !important
  }

  .align-items-xl-stretch {
    align-items: stretch !important
  }

  .align-content-xl-start {
    align-content: flex-start !important
  }

  .align-content-xl-end {
    align-content: flex-end !important
  }

  .align-content-xl-center {
    align-content: center !important
  }

  .align-content-xl-between {
    align-content: space-between !important
  }

  .align-content-xl-around {
    align-content: space-around !important
  }

  .align-content-xl-stretch {
    align-content: stretch !important
  }

  .align-self-xl-auto {
    align-self: auto !important
  }

  .align-self-xl-start {
    align-self: flex-start !important
  }

  .align-self-xl-end {
    align-self: flex-end !important
  }

  .align-self-xl-center {
    align-self: center !important
  }

  .align-self-xl-baseline {
    align-self: baseline !important
  }

  .align-self-xl-stretch {
    align-self: stretch !important
  }

  .order-xl-first {
    order: -1 !important
  }

  .order-xl-0 {
    order: 0 !important
  }

  .order-xl-1 {
    order: 1 !important
  }

  .order-xl-2 {
    order: 2 !important
  }

  .order-xl-3 {
    order: 3 !important
  }

  .order-xl-4 {
    order: 4 !important
  }

  .order-xl-5 {
    order: 5 !important
  }

  .order-xl-last {
    order: 6 !important
  }

  .m-xl-0 {
    margin: 0 !important
  }

  .m-xl-1 {
    margin: .25rem !important
  }

  .m-xl-2 {
    margin: .5rem !important
  }

  .m-xl-3 {
    margin: 1rem !important
  }

  .m-xl-4 {
    margin: 1.5rem !important
  }

  .m-xl-5 {
    margin: 3rem !important
  }

  .m-xl-auto {
    margin: auto !important
  }

  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important
  }

  .mx-xl-1 {
    margin-right: .25rem !important;
    margin-left: .25rem !important
  }

  .mx-xl-2 {
    margin-right: .5rem !important;
    margin-left: .5rem !important
  }

  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important
  }

  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important
  }

  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important
  }

  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important
  }

  .my-xl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important
  }

  .my-xl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important
  }

  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important
  }

  .mt-xl-0 {
    margin-top: 0 !important
  }

  .mt-xl-1 {
    margin-top: .25rem !important
  }

  .mt-xl-2 {
    margin-top: .5rem !important
  }

  .mt-xl-3 {
    margin-top: 1rem !important
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important
  }

  .mt-xl-5 {
    margin-top: 3rem !important
  }

  .mt-xl-auto {
    margin-top: auto !important
  }

  .me-xl-0 {
    margin-right: 0 !important
  }

  .me-xl-1 {
    margin-right: .25rem !important
  }

  .me-xl-2 {
    margin-right: .5rem !important
  }

  .me-xl-3 {
    margin-right: 1rem !important
  }

  .me-xl-4 {
    margin-right: 1.5rem !important
  }

  .me-xl-5 {
    margin-right: 3rem !important
  }

  .me-xl-auto {
    margin-right: auto !important
  }

  .mb-xl-0 {
    margin-bottom: 0 !important
  }

  .mb-xl-1 {
    margin-bottom: .25rem !important
  }

  .mb-xl-2 {
    margin-bottom: .5rem !important
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important
  }

  .mb-xl-5 {
    margin-bottom: 3rem !important
  }

  .mb-xl-auto {
    margin-bottom: auto !important
  }

  .ms-xl-0 {
    margin-left: 0 !important
  }

  .ms-xl-1 {
    margin-left: .25rem !important
  }

  .ms-xl-2 {
    margin-left: .5rem !important
  }

  .ms-xl-3 {
    margin-left: 1rem !important
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important
  }

  .ms-xl-5 {
    margin-left: 3rem !important
  }

  .ms-xl-auto {
    margin-left: auto !important
  }

  .p-xl-0 {
    padding: 0 !important
  }

  .p-xl-1 {
    padding: .25rem !important
  }

  .p-xl-2 {
    padding: .5rem !important
  }

  .p-xl-3 {
    padding: 1rem !important
  }

  .p-xl-4 {
    padding: 1.5rem !important
  }

  .p-xl-5 {
    padding: 3rem !important
  }

  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important
  }

  .px-xl-1 {
    padding-right: .25rem !important;
    padding-left: .25rem !important
  }

  .px-xl-2 {
    padding-right: .5rem !important;
    padding-left: .5rem !important
  }

  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important
  }

  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important
  }

  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important
  }

  .py-xl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important
  }

  .py-xl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important
  }

  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important
  }

  .pt-xl-0 {
    padding-top: 0 !important
  }

  .pt-xl-1 {
    padding-top: .25rem !important
  }

  .pt-xl-2 {
    padding-top: .5rem !important
  }

  .pt-xl-3 {
    padding-top: 1rem !important
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important
  }

  .pt-xl-5 {
    padding-top: 3rem !important
  }

  .pe-xl-0 {
    padding-right: 0 !important
  }

  .pe-xl-1 {
    padding-right: .25rem !important
  }

  .pe-xl-2 {
    padding-right: .5rem !important
  }

  .pe-xl-3 {
    padding-right: 1rem !important
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important
  }

  .pe-xl-5 {
    padding-right: 3rem !important
  }

  .pb-xl-0 {
    padding-bottom: 0 !important
  }

  .pb-xl-1 {
    padding-bottom: .25rem !important
  }

  .pb-xl-2 {
    padding-bottom: .5rem !important
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important
  }

  .pb-xl-5 {
    padding-bottom: 3rem !important
  }

  .ps-xl-0 {
    padding-left: 0 !important
  }

  .ps-xl-1 {
    padding-left: .25rem !important
  }

  .ps-xl-2 {
    padding-left: .5rem !important
  }

  .ps-xl-3 {
    padding-left: 1rem !important
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important
  }

  .ps-xl-5 {
    padding-left: 3rem !important
  }
}

@media (min-width:1400px) {
  .d-xxl-inline {
    display: inline !important
  }

  .d-xxl-inline-block {
    display: inline-block !important
  }

  .d-xxl-block {
    display: block !important
  }

  .d-xxl-grid {
    display: grid !important
  }

  .d-xxl-table {
    display: table !important
  }

  .d-xxl-table-row {
    display: table-row !important
  }

  .d-xxl-table-cell {
    display: table-cell !important
  }

  .d-xxl-flex {
    display: flex !important
  }

  .d-xxl-inline-flex {
    display: inline-flex !important
  }

  .d-xxl-none {
    display: none !important
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important
  }

  .flex-xxl-row {
    flex-direction: row !important
  }

  .flex-xxl-column {
    flex-direction: column !important
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important
  }

  .justify-content-xxl-center {
    justify-content: center !important
  }

  .justify-content-xxl-between {
    justify-content: space-between !important
  }

  .justify-content-xxl-around {
    justify-content: space-around !important
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important
  }

  .align-items-xxl-start {
    align-items: flex-start !important
  }

  .align-items-xxl-end {
    align-items: flex-end !important
  }

  .align-items-xxl-center {
    align-items: center !important
  }

  .align-items-xxl-baseline {
    align-items: baseline !important
  }

  .align-items-xxl-stretch {
    align-items: stretch !important
  }

  .align-content-xxl-start {
    align-content: flex-start !important
  }

  .align-content-xxl-end {
    align-content: flex-end !important
  }

  .align-content-xxl-center {
    align-content: center !important
  }

  .align-content-xxl-between {
    align-content: space-between !important
  }

  .align-content-xxl-around {
    align-content: space-around !important
  }

  .align-content-xxl-stretch {
    align-content: stretch !important
  }

  .align-self-xxl-auto {
    align-self: auto !important
  }

  .align-self-xxl-start {
    align-self: flex-start !important
  }

  .align-self-xxl-end {
    align-self: flex-end !important
  }

  .align-self-xxl-center {
    align-self: center !important
  }

  .align-self-xxl-baseline {
    align-self: baseline !important
  }

  .align-self-xxl-stretch {
    align-self: stretch !important
  }

  .order-xxl-first {
    order: -1 !important
  }

  .order-xxl-0 {
    order: 0 !important
  }

  .order-xxl-1 {
    order: 1 !important
  }

  .order-xxl-2 {
    order: 2 !important
  }

  .order-xxl-3 {
    order: 3 !important
  }

  .order-xxl-4 {
    order: 4 !important
  }

  .order-xxl-5 {
    order: 5 !important
  }

  .order-xxl-last {
    order: 6 !important
  }

  .m-xxl-0 {
    margin: 0 !important
  }

  .m-xxl-1 {
    margin: .25rem !important
  }

  .m-xxl-2 {
    margin: .5rem !important
  }

  .m-xxl-3 {
    margin: 1rem !important
  }

  .m-xxl-4 {
    margin: 1.5rem !important
  }

  .m-xxl-5 {
    margin: 3rem !important
  }

  .m-xxl-auto {
    margin: auto !important
  }

  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important
  }

  .mx-xxl-1 {
    margin-right: .25rem !important;
    margin-left: .25rem !important
  }

  .mx-xxl-2 {
    margin-right: .5rem !important;
    margin-left: .5rem !important
  }

  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important
  }

  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important
  }

  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important
  }

  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important
  }

  .my-xxl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important
  }

  .my-xxl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important
  }

  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important
  }

  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important
  }

  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important
  }

  .mt-xxl-0 {
    margin-top: 0 !important
  }

  .mt-xxl-1 {
    margin-top: .25rem !important
  }

  .mt-xxl-2 {
    margin-top: .5rem !important
  }

  .mt-xxl-3 {
    margin-top: 1rem !important
  }

  .mt-xxl-4 {
    margin-top: 1.5rem !important
  }

  .mt-xxl-5 {
    margin-top: 3rem !important
  }

  .mt-xxl-auto {
    margin-top: auto !important
  }

  .me-xxl-0 {
    margin-right: 0 !important
  }

  .me-xxl-1 {
    margin-right: .25rem !important
  }

  .me-xxl-2 {
    margin-right: .5rem !important
  }

  .me-xxl-3 {
    margin-right: 1rem !important
  }

  .me-xxl-4 {
    margin-right: 1.5rem !important
  }

  .me-xxl-5 {
    margin-right: 3rem !important
  }

  .me-xxl-auto {
    margin-right: auto !important
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important
  }

  .mb-xxl-1 {
    margin-bottom: .25rem !important
  }

  .mb-xxl-2 {
    margin-bottom: .5rem !important
  }

  .mb-xxl-3 {
    margin-bottom: 1rem !important
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important
  }

  .mb-xxl-5 {
    margin-bottom: 3rem !important
  }

  .mb-xxl-auto {
    margin-bottom: auto !important
  }

  .ms-xxl-0 {
    margin-left: 0 !important
  }

  .ms-xxl-1 {
    margin-left: .25rem !important
  }

  .ms-xxl-2 {
    margin-left: .5rem !important
  }

  .ms-xxl-3 {
    margin-left: 1rem !important
  }

  .ms-xxl-4 {
    margin-left: 1.5rem !important
  }

  .ms-xxl-5 {
    margin-left: 3rem !important
  }

  .ms-xxl-auto {
    margin-left: auto !important
  }

  .p-xxl-0 {
    padding: 0 !important
  }

  .p-xxl-1 {
    padding: .25rem !important
  }

  .p-xxl-2 {
    padding: .5rem !important
  }

  .p-xxl-3 {
    padding: 1rem !important
  }

  .p-xxl-4 {
    padding: 1.5rem !important
  }

  .p-xxl-5 {
    padding: 3rem !important
  }

  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important
  }

  .px-xxl-1 {
    padding-right: .25rem !important;
    padding-left: .25rem !important
  }

  .px-xxl-2 {
    padding-right: .5rem !important;
    padding-left: .5rem !important
  }

  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important
  }

  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important
  }

  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important
  }

  .py-xxl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important
  }

  .py-xxl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important
  }

  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important
  }

  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important
  }

  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important
  }

  .pt-xxl-0 {
    padding-top: 0 !important
  }

  .pt-xxl-1 {
    padding-top: .25rem !important
  }

  .pt-xxl-2 {
    padding-top: .5rem !important
  }

  .pt-xxl-3 {
    padding-top: 1rem !important
  }

  .pt-xxl-4 {
    padding-top: 1.5rem !important
  }

  .pt-xxl-5 {
    padding-top: 3rem !important
  }

  .pe-xxl-0 {
    padding-right: 0 !important
  }

  .pe-xxl-1 {
    padding-right: .25rem !important
  }

  .pe-xxl-2 {
    padding-right: .5rem !important
  }

  .pe-xxl-3 {
    padding-right: 1rem !important
  }

  .pe-xxl-4 {
    padding-right: 1.5rem !important
  }

  .pe-xxl-5 {
    padding-right: 3rem !important
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important
  }

  .pb-xxl-1 {
    padding-bottom: .25rem !important
  }

  .pb-xxl-2 {
    padding-bottom: .5rem !important
  }

  .pb-xxl-3 {
    padding-bottom: 1rem !important
  }

  .pb-xxl-4 {
    padding-bottom: 1.5rem !important
  }

  .pb-xxl-5 {
    padding-bottom: 3rem !important
  }

  .ps-xxl-0 {
    padding-left: 0 !important
  }

  .ps-xxl-1 {
    padding-left: .25rem !important
  }

  .ps-xxl-2 {
    padding-left: .5rem !important
  }

  .ps-xxl-3 {
    padding-left: 1rem !important
  }

  .ps-xxl-4 {
    padding-left: 1.5rem !important
  }

  .ps-xxl-5 {
    padding-left: 3rem !important
  }
}

@media print {
  .d-print-inline {
    display: inline !important
  }

  .d-print-inline-block {
    display: inline-block !important
  }

  .d-print-block {
    display: block !important
  }

  .d-print-grid {
    display: grid !important
  }

  .d-print-table {
    display: table !important
  }

  .d-print-table-row {
    display: table-row !important
  }

  .d-print-table-cell {
    display: table-cell !important
  }

  .d-print-flex {
    display: flex !important
  }

  .d-print-inline-flex {
    display: inline-flex !important
  }

  .d-print-none {
    display: none !important
  }
}

@font-face {
  font-family: "icomoon";
  src: url("../font/icomoon.26fba056.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: block
}

[class*=" ti-"],
[class^=ti-] {
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: 400;
  -webkit-font-feature-settings: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

.ti-image:before {
  content: "\ea5d"
}

.ti-layout:before {
  content: "\ea5e"
}

.ti-ruler-pencil:before {
  content: "\ea5f"
}

.ti-agenda:before {
  content: "\e900"
}

.ti-alarm-clock:before {
  content: "\e901"
}

.ti-alert:before {
  content: "\e902"
}

.ti-align-center:before {
  content: "\e903"
}

.ti-align-justify:before {
  content: "\e904"
}

.ti-align-left:before {
  content: "\e905"
}

.ti-align-right:before {
  content: "\e906"
}

.ti-anchor:before {
  content: "\e907"
}

.ti-android:before {
  content: "\e908"
}

.ti-angle-double-down:before {
  content: "\e909"
}

.ti-angle-double-left:before {
  content: "\e90a"
}

.ti-angle-double-right:before {
  content: "\e90b"
}

.ti-angle-double-up:before {
  content: "\e90c"
}

.ti-angle-down:before {
  content: "\e90d"
}

.ti-angle-left:before {
  content: "\e90e"
}

.ti-angle-right:before {
  content: "\e90f"
}

.ti-angle-up:before {
  content: "\e910"
}

.ti-announcement:before {
  content: "\e911"
}

.ti-apple:before {
  content: "\e912"
}

.ti-archive:before {
  content: "\e913"
}

.ti-arrow:before {
  content: "\e914"
}

.ti-arrow-circle-down:before {
  content: "\e915"
}

.ti-arrow-circle-left:before {
  content: "\e916"
}

.ti-arrow-circle-right:before {
  content: "\e917"
}

.ti-arrow-circle-up:before {
  content: "\e918"
}

.ti-arrow-down:before {
  content: "\e919"
}

.ti-arrow-left:before {
  content: "\e91a"
}

.ti-arrow-right:before {
  content: "\e91b"
}

.ti-arrows-corner:before {
  content: "\e91c"
}

.ti-arrows-horizontal:before {
  content: "\e91d"
}

.ti-arrows-vertical:before {
  content: "\e91e"
}

.ti-arrow-top-left:before {
  content: "\e91f"
}

.ti-arrow-top-right:before {
  content: "\e920"
}

.ti-arrow-up:before {
  content: "\e921"
}

.ti-back-left:before {
  content: "\e922"
}

.ti-back-right:before {
  content: "\e923"
}

.ti-bag:before {
  content: "\e924"
}

.ti-bar-chart:before {
  content: "\e925"
}

.ti-bar-chart-alt:before {
  content: "\e926"
}

.ti-basketball:before {
  content: "\e927"
}

.ti-bell:before {
  content: "\e928"
}

.ti-blackboard:before {
  content: "\e929"
}

.ti-bolt:before {
  content: "\e92a"
}

.ti-bolt-alt:before {
  content: "\e92b"
}

.ti-book:before {
  content: "\e92c"
}

.ti-bookmark:before {
  content: "\e92d"
}

.ti-bookmark-alt:before {
  content: "\e92e"
}

.ti-briefcase:before {
  content: "\e92f"
}

.ti-brush:before {
  content: "\e930"
}

.ti-brush-alt:before {
  content: "\e931"
}

.ti-calendar:before {
  content: "\e932"
}

.ti-camera:before {
  content: "\e933"
}

.ti-car:before {
  content: "\e934"
}

.ti-check:before {
  content: "\e935"
}

.ti-check-box:before {
  content: "\e936"
}

.ti-clip:before {
  content: "\e937"
}

.ti-clipboard:before {
  content: "\e938"
}

.ti-close:before {
  content: "\e939"
}

.ti-cloud:before {
  content: "\e93a"
}

.ti-cloud-down:before {
  content: "\e93b"
}

.ti-cloud-up:before {
  content: "\e93c"
}

.ti-comment:before {
  content: "\e93d"
}

.ti-comment-alt:before {
  content: "\e93e"
}

.ti-comments:before {
  content: "\e93f"
}

.ti-comments-smiley:before {
  content: "\e940"
}

.ti-control-backward:before {
  content: "\e941"
}

.ti-control-eject:before {
  content: "\e942"
}

.ti-control-forward:before {
  content: "\e943"
}

.ti-control-pause:before {
  content: "\e944"
}

.ti-control-play:before {
  content: "\e945"
}

.ti-control-record:before {
  content: "\e946"
}

.ti-control-shuffle:before {
  content: "\e947"
}

.ti-control-skip-backward:before {
  content: "\e948"
}

.ti-control-skip-forward:before {
  content: "\e949"
}

.ti-control-stop:before {
  content: "\e94a"
}

.ti-credit-card:before {
  content: "\e94b"
}

.ti-crown:before {
  content: "\e94c"
}

.ti-css3:before {
  content: "\e94d"
}

.ti-cup:before {
  content: "\e94e"
}

.ti-cut:before {
  content: "\e94f"
}

.ti-dashboard:before {
  content: "\e950"
}

.ti-desktop:before {
  content: "\e951"
}

.ti-direction:before {
  content: "\e952"
}

.ti-direction-alt:before {
  content: "\e953"
}

.ti-download:before {
  content: "\e954"
}

.ti-dribbble:before {
  content: "\e955"
}

.ti-dropbox:before {
  content: "\e956"
}

.ti-dropbox-alt:before {
  content: "\e957"
}

.ti-drupal:before {
  content: "\e958"
}

.ti-email:before {
  content: "\e959"
}

.ti-envelope:before {
  content: "\e95a"
}

.ti-eraser:before {
  content: "\e95b"
}

.ti-exchange-vertical:before {
  content: "\e95c"
}

.ti-export:before {
  content: "\e95d"
}

.ti-eye:before {
  content: "\e95e"
}

.ti-facebook:before {
  content: "\e95f"
}

.ti-face-sad:before {
  content: "\e960"
}

.ti-face-smile:before {
  content: "\e961"
}

.ti-file:before {
  content: "\e962"
}

.ti-files:before {
  content: "\e963"
}

.ti-filter:before {
  content: "\e964"
}

.ti-flag:before {
  content: "\e965"
}

.ti-flag-alt:before {
  content: "\e966"
}

.ti-flag-alt-2:before {
  content: "\e967"
}

.ti-flickr:before {
  content: "\e968"
}

.ti-flickr-alt:before {
  content: "\e969"
}

.ti-folder:before {
  content: "\e96a"
}

.ti-fullscreen:before {
  content: "\e96b"
}

.ti-gallery:before {
  content: "\e96c"
}

.ti-game:before {
  content: "\e96d"
}

.ti-gift:before {
  content: "\e96e"
}

.ti-github:before {
  content: "\e96f"
}

.ti-google:before {
  content: "\e970"
}

.ti-hand-drag:before {
  content: "\e971"
}

.ti-hand-open:before {
  content: "\e972"
}

.ti-hand-point-down:before {
  content: "\e973"
}

.ti-hand-point-left:before {
  content: "\e974"
}

.ti-hand-point-right:before {
  content: "\e975"
}

.ti-hand-point-up:before {
  content: "\e976"
}

.ti-hand-stop:before {
  content: "\e977"
}

.ti-harddrive:before {
  content: "\e978"
}

.ti-harddrives:before {
  content: "\e979"
}

.ti-headphone:before {
  content: "\e97a"
}

.ti-headphone-alt:before {
  content: "\e97b"
}

.ti-heart:before {
  content: "\e97c"
}

.ti-heart-broken:before {
  content: "\e97d"
}

.ti-help:before {
  content: "\e97e"
}

.ti-help-alt:before {
  content: "\e97f"
}

.ti-home:before {
  content: "\e980"
}

.ti-html5:before {
  content: "\e981"
}

.ti-hummer:before {
  content: "\e982"
}

.ti-id-badge:before {
  content: "\e983"
}

.ti-import:before {
  content: "\e984"
}

.ti-infinite:before {
  content: "\e985"
}

.ti-info:before {
  content: "\e986"
}

.ti-info-alt:before {
  content: "\e987"
}

.ti-ink-pen:before {
  content: "\e988"
}

.ti-instagram:before {
  content: "\e989"
}

.ti-Italic:before {
  content: "\e98a"
}

.ti-joomla:before {
  content: "\e98b"
}

.ti-jsfiddle:before {
  content: "\e98c"
}

.ti-key:before {
  content: "\e98d"
}

.ti-layers:before {
  content: "\e98e"
}

.ti-layers-alt:before {
  content: "\e98f"
}

.ti-layout-accordion-list:before {
  content: "\e990"
}

.ti-layout-accordion-merged:before {
  content: "\e991"
}

.ti-layout-accordion-separated:before {
  content: "\e992"
}

.ti-layout-column2:before {
  content: "\e993"
}

.ti-layout-column2-alt:before {
  content: "\e994"
}

.ti-layout-column3:before {
  content: "\e995"
}

.ti-layout-column3-alt:before {
  content: "\e996"
}

.ti-layout-column4:before {
  content: "\e997"
}

.ti-layout-column4-alt:before {
  content: "\e998"
}

.ti-layout-cta-btn-left:before {
  content: "\e999"
}

.ti-layout-cta-btn-right:before {
  content: "\e99a"
}

.ti-layout-cta-center:before {
  content: "\e99b"
}

.ti-layout-cta-left:before {
  content: "\e99c"
}

.ti-layout-cta-right:before {
  content: "\e99d"
}

.ti-layout-grid2:before {
  content: "\e99e"
}

.ti-layout-grid2-alt:before {
  content: "\e99f"
}

.ti-layout-grid2-thumb:before {
  content: "\e9a0"
}

.ti-layout-grid3:before {
  content: "\e9a1"
}

.ti-layout-grid3-alt:before {
  content: "\e9a2"
}

.ti-layout-grid4:before {
  content: "\e9a3"
}

.ti-layout-grid4-alt:before {
  content: "\e9a4"
}

.ti-layout-line-solid:before {
  content: "\e9a5"
}

.ti-layout-list-large-image:before {
  content: "\e9a6"
}

.ti-layout-list-post:before {
  content: "\e9a7"
}

.ti-layout-list-thumb:before {
  content: "\e9a8"
}

.ti-layout-list-thumb-alt:before {
  content: "\e9a9"
}

.ti-layout-media-center:before {
  content: "\e9aa"
}

.ti-layout-media-center-alt:before {
  content: "\e9ab"
}

.ti-layout-media-left:before {
  content: "\e9ac"
}

.ti-layout-media-left-alt:before {
  content: "\e9ad"
}

.ti-layout-media-overlay:before {
  content: "\e9ae"
}

.ti-layout-media-overlay-alt:before {
  content: "\e9af"
}

.ti-layout-media-overlay-alt-2:before {
  content: "\e9b0"
}

.ti-layout-media-right:before {
  content: "\e9b1"
}

.ti-layout-media-right-alt:before {
  content: "\e9b2"
}

.ti-layout-menu:before {
  content: "\e9b3"
}

.ti-layout-menu-full:before {
  content: "\e9b4"
}

.ti-layout-menu-separated:before {
  content: "\e9b5"
}

.ti-layout-menu-v:before {
  content: "\e9b6"
}

.ti-layout-placeholder:before {
  content: "\e9b7"
}

.ti-layout-sidebar-2:before {
  content: "\e9b8"
}

.ti-layout-sidebar-left:before {
  content: "\e9b9"
}

.ti-layout-sidebar-none:before {
  content: "\e9ba"
}

.ti-layout-sidebar-right:before {
  content: "\e9bb"
}

.ti-layout-slider:before {
  content: "\e9bc"
}

.ti-layout-slider-alt:before {
  content: "\e9bd"
}

.ti-layout-tab:before {
  content: "\e9be"
}

.ti-layout-tab-min:before {
  content: "\e9bf"
}

.ti-layout-tab-v:before {
  content: "\e9c0"
}

.ti-layout-tab-window:before {
  content: "\e9c1"
}

.ti-layout-width-default:before {
  content: "\e9c2"
}

.ti-layout-width-default-alt:before {
  content: "\e9c3"
}

.ti-layout-width-full:before {
  content: "\e9c4"
}

.ti-light-bulb:before {
  content: "\e9c5"
}

.ti-line-dashed:before {
  content: "\e9c6"
}

.ti-line-dotted:before {
  content: "\e9c7"
}

.ti-line-double:before {
  content: "\e9c8"
}

.ti-link:before {
  content: "\e9c9"
}

.ti-linkedin:before {
  content: "\e9ca"
}

.ti-linux:before {
  content: "\e9cb"
}

.ti-list:before {
  content: "\e9cc"
}

.ti-list-ol:before {
  content: "\e9cd"
}

.ti-location-arrow:before {
  content: "\e9ce"
}

.ti-location-pin:before {
  content: "\e9cf"
}

.ti-lock:before {
  content: "\e9d0"
}

.ti-loop:before {
  content: "\e9d1"
}

.ti-magnet:before {
  content: "\e9d2"
}

.ti-map:before {
  content: "\e9d3"
}

.ti-map-alt:before {
  content: "\e9d4"
}

.ti-marker:before {
  content: "\e9d5"
}

.ti-marker-alt:before {
  content: "\e9d6"
}

.ti-medall:before {
  content: "\e9d7"
}

.ti-medall-alt:before {
  content: "\e9d8"
}

.ti-menu:before {
  content: "\e9d9"
}

.ti-menu-alt:before {
  content: "\e9da"
}

.ti-microphone:before {
  content: "\e9db"
}

.ti-microphone-alt:before {
  content: "\e9dc"
}

.ti-microsoft:before {
  content: "\e9dd"
}

.ti-microsoft-alt:before {
  content: "\e9de"
}

.ti-minus:before {
  content: "\e9df"
}

.ti-mobile:before {
  content: "\e9e0"
}

.ti-money:before {
  content: "\e9e1"
}

.ti-more:before {
  content: "\e9e2"
}

.ti-more-alt:before {
  content: "\e9e3"
}

.ti-mouse:before {
  content: "\e9e4"
}

.ti-mouse-alt:before {
  content: "\e9e5"
}

.ti-music:before {
  content: "\e9e6"
}

.ti-music-alt:before {
  content: "\e9e7"
}

.ti-na:before {
  content: "\e9e8"
}

.ti-new-window:before {
  content: "\e9e9"
}

.ti-notepad:before {
  content: "\e9ea"
}

.ti-package:before {
  content: "\e9eb"
}

.ti-paint-bucket:before {
  content: "\e9ec"
}

.ti-paint-roller:before {
  content: "\e9ed"
}

.ti-palette:before {
  content: "\e9ee"
}

.ti-panel:before {
  content: "\e9ef"
}

.ti-paragraph:before {
  content: "\e9f0"
}

.ti-pencil:before {
  content: "\e9f1"
}

.ti-pencil-alt:before {
  content: "\e9f2"
}

.ti-pencil-alt2:before {
  content: "\e9f3"
}

.ti-pie-chart:before {
  content: "\e9f4"
}

.ti-pin:before {
  content: "\e9f5"
}

.ti-pin2:before {
  content: "\e9f6"
}

.ti-pin-alt:before {
  content: "\e9f7"
}

.ti-pinterest:before {
  content: "\e9f8"
}

.ti-pinterest-alt:before {
  content: "\e9f9"
}

.ti-plug:before {
  content: "\e9fa"
}

.ti-plus:before {
  content: "\e9fb"
}

.ti-power-off:before {
  content: "\e9fc"
}

.ti-printer:before {
  content: "\e9fd"
}

.ti-pulse:before {
  content: "\e9fe"
}

.ti-quote-left:before {
  content: "\e9ff"
}

.ti-quote-right:before {
  content: "\ea00"
}

.ti-receipt:before {
  content: "\ea01"
}

.ti-reddit:before {
  content: "\ea02"
}

.ti-reload:before {
  content: "\ea03"
}

.ti-rocket:before {
  content: "\ea04"
}

.ti-rss:before {
  content: "\ea05"
}

.ti-rss-alt:before {
  content: "\ea06"
}

.ti-ruler:before {
  content: "\ea07"
}

.ti-ruler-alt:before {
  content: "\ea08"
}

.ti-ruler-alt-2:before {
  content: "\ea09"
}

.ti-save:before {
  content: "\ea0a"
}

.ti-save-alt:before {
  content: "\ea0b"
}

.ti-search:before {
  content: "\ea0c"
}

.ti-server:before {
  content: "\ea0d"
}

.ti-settings:before {
  content: "\ea0e"
}

.ti-share:before {
  content: "\ea0f"
}

.ti-share-alt:before {
  content: "\ea10"
}

.ti-sharethis:before {
  content: "\ea11"
}

.ti-sharethis-alt:before {
  content: "\ea12"
}

.ti-shield:before {
  content: "\ea13"
}

.ti-shift-left:before {
  content: "\ea14"
}

.ti-shift-left-alt:before {
  content: "\ea15"
}

.ti-shift-right:before {
  content: "\ea16"
}

.ti-shift-right-alt:before {
  content: "\ea17"
}

.ti-shine:before {
  content: "\ea18"
}

.ti-shopping-cart:before {
  content: "\ea19"
}

.ti-shopping-cart-full:before {
  content: "\ea1a"
}

.ti-shortcode:before {
  content: "\ea1b"
}

.ti-signal:before {
  content: "\ea1c"
}

.ti-skype:before {
  content: "\ea1d"
}

.ti-slice:before {
  content: "\ea1e"
}

.ti-smallcap:before {
  content: "\ea1f"
}

.ti-soundcloud:before {
  content: "\ea20"
}

.ti-split-h:before {
  content: "\ea21"
}

.ti-split-v:before {
  content: "\ea22"
}

.ti-split-v-alt:before {
  content: "\ea23"
}

.ti-spray:before {
  content: "\ea24"
}

.ti-stack-overflow:before {
  content: "\ea25"
}

.ti-stamp:before {
  content: "\ea26"
}

.ti-star:before {
  content: "\ea27"
}

.ti-stats-down:before {
  content: "\ea28"
}

.ti-stats-up:before {
  content: "\ea29"
}

.ti-support:before {
  content: "\ea2a"
}

.ti-tablet:before {
  content: "\ea2b"
}

.ti-tag:before {
  content: "\ea2c"
}

.ti-target:before {
  content: "\ea2d"
}

.ti-text:before {
  content: "\ea2e"
}

.ti-themify-favicon:before {
  content: "\ea2f"
}

.ti-themify-favti-alt:before {
  content: "\ea30"
}

.ti-themify-logo:before {
  content: "\ea31"
}

.ti-thought:before {
  content: "\ea32"
}

.ti-thumb-down:before {
  content: "\ea33"
}

.ti-thumb-up:before {
  content: "\ea34"
}

.ti-ticket:before {
  content: "\ea35"
}

.ti-time:before {
  content: "\ea36"
}

.ti-timer:before {
  content: "\ea37"
}

.ti-trash:before {
  content: "\ea38"
}

.ti-trello:before {
  content: "\ea39"
}

.ti-truck:before {
  content: "\ea3a"
}

.ti-tumblr:before {
  content: "\ea3b"
}

.ti-tumblr-alt:before {
  content: "\ea3c"
}

.ti-twitter:before {
  content: "\ea3d"
}

.ti-twitter-alt:before {
  content: "\ea3e"
}

.ti-underline:before {
  content: "\ea3f"
}

.ti-unlink:before {
  content: "\ea40"
}

.ti-unlock:before {
  content: "\ea41"
}

.ti-upload:before {
  content: "\ea42"
}

.ti-uppercase:before {
  content: "\ea43"
}

.ti-user:before {
  content: "\ea44"
}

.ti-vector:before {
  content: "\ea45"
}

.ti-video-camera:before {
  content: "\ea46"
}

.ti-video-clapper:before {
  content: "\ea47"
}

.ti-view-grid:before {
  content: "\ea48"
}

.ti-view-list:before {
  content: "\ea49"
}

.ti-view-list-alt:before {
  content: "\ea4a"
}

.ti-vimeo:before {
  content: "\ea4b"
}

.ti-vimeo-alt:before {
  content: "\ea4c"
}

.ti-volume:before {
  content: "\ea4d"
}

.ti-wallet:before {
  content: "\ea4e"
}

.ti-wand:before {
  content: "\ea4f"
}

.ti-wheelchair:before {
  content: "\ea50"
}

.ti-widget:before {
  content: "\ea51"
}

.ti-widget-alt:before {
  content: "\ea52"
}

.ti-widgetized:before {
  content: "\ea53"
}

.ti-window:before {
  content: "\ea54"
}

.ti-wordpress:before {
  content: "\ea55"
}

.ti-world:before {
  content: "\ea56"
}

.ti-write:before {
  content: "\ea57"
}

.ti-yahoo:before {
  content: "\ea58"
}

.ti-youtube:before {
  content: "\ea59"
}

.ti-zip:before {
  content: "\ea5a"
}

.ti-zoom-in:before {
  content: "\ea5b"
}

.ti-zoom-out:before {
  content: "\ea5c"
}

*,
:after,
:before {
  box-sizing: border-box
}

html {
  font-family: sans-serif;
  line-height: 1.15
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  line-height: 1.2
}

p {
  margin-top: 0
}

abbr[data-original-title],
abbr[title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none
}

address {
  font-style: normal;
  line-height: inherit
}

address,
dl,
ol,
ul {
  margin-bottom: 1rem
}

dl,
ol,
ul {
  margin-top: 0
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: .6rem;
  font-weight: 500;
  line-height: 1.2
}

.h5,
h5 {
  font-size: 1.25rem
}

dd {
  margin-bottom: .5rem;
  margin-left: 0
}

blockquote {
  margin: 0 0 1rem
}

b,
strong {
  font-weight: 700
}

sub,
sup {
  position: relative;
  line-height: 0;
  vertical-align: baseline
}

sub {
  bottom: -.25em
}

sup {
  top: -.5em
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none
}

a {
  text-decoration: none;
  background-color: transparent
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar
}

figure {
  margin: 0 0 1rem
}

img {
  border-style: none
}

img,
svg {
  vertical-align: middle
}

svg {
  overflow: hidden
}

table {
  border-collapse: collapse
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent
}

label {
  display: inline-block
}

button {
  border-radius: 0
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  line-height: inherit
}

button,
input {
  overflow: visible
}

button,
select {
  text-transform: none
}

[role=button] {
  cursor: pointer
}

select {
  word-wrap: normal
}

[type=button],
[type=reset],
[type=submit],
button {
  -webkit-appearance: button
}

[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border-style: none
}

input[type=checkbox],
input[type=radio] {
  box-sizing: border-box;
  padding: 0
}

textarea {
  overflow: auto;
  resize: vertical
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal
}

progress {
  vertical-align: baseline
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button
}

output {
  display: inline-block
}

summary {
  display: list-item;
  cursor: pointer
}

template {
  display: none
}

[hidden] {
  display: none !important
}

li,
ul {
  list-style: none
}

html {
  scroll-behavior: smooth
}

body,
html {
  overflow-x: hidden
}

body {
  color: #5e6472;
  font-family: "Nunito", monospac;
  line-height: 1.7;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  padding: 0
}

.font-alt {
  font-family: "Nunito", serif
}

.text-center {
  text-align: center
}

img {
  max-width: 100%
}

* {
  outline: none !important
}

a {
  color: #dc965a;
  transition: .3s
}

a:hover {
  color: #d58138;
  text-decoration: none
}

.lead {
  font-size: 1.25rem;
  font-weight: 300
}

mark {
  background-image: linear-gradient(#dc965a, #dc965a);
  background-size: 100% 3px;
  background-repeat: no-repeat;
  background-position: 0 bottom;
  background-color: transparent;
  padding: 0;
  color: currentColor
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.ml-auto,
.mx-auto {
  margin-left: auto !important
}

.invalid-feedback {
  color: #dc3545
}

.invalid-feedback,
.valid-feedback {
  width: 100%;
  margin-top: 5px;
  font-size: 15px;
  display: block
}

.valid-feedback {
  color: #198754
}

.scroll_up {
  position: fixed;
  right: 20px;
  bottom: 20px;
  background-color: #fff;
  color: #dc965a;
  width: 42px;
  height: 45px;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  transition: all .3s ease-in-out;
  box-shadow: 0 0 30px rgba(0, 0, 0, .15);
  font-size: 20px;
  cursor: pointer
}

.scroll_up:hover {
  background: #dc965a;
  color: #fff;
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px)
}

.scroll_up span {
  font-weight: 600
}

@media screen and (max-width:767px) {
  .text-sm-center {
    text-align: center
  }
}

.form-group {
  margin-bottom: 1rem
}

textarea.form-control {
  height: auto
}

.me-auto {
  margin-right: auto !important
}

a {
  cursor: pointer !important
}

#loading {
  background: #dc965a;
  z-index: 99999
}

#loading,
.load-circle {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0
}

.load-circle {
  margin: auto;
  width: 50px;
  height: 50px
}

.load-circle span {
  display: inline-block;
  width: 64px;
  height: 64px
}

.load-circle span:after {
  content: " ";
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border-color: #fff transparent;
  border-style: solid;
  border-width: 5px;
  -webkit-animation: lds-dual-ring 1.2s linear infinite;
  animation: lds-dual-ring 1.2s linear infinite
}

@-webkit-keyframes lds-dual-ring {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
  }

  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn)
  }
}

@keyframes lds-dual-ring {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
  }

  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn)
  }
}

.owl-dots {
  text-align: center
}

.owl-dots .owl-dot {
  display: inline-block;
  vertical-align: top;
  width: 10px;
  height: 10px;
  background: transparent;
  border: 1px solid #1c1d24;
  transition: all .55s ease;
  border-radius: 50%;
  margin: 0 5px
}

.owl-dots .owl-dot.active {
  background: #1c1d24
}

.owl-carousel .owl-item img {
  width: auto;
  max-width: 100%
}

.page-title {
  padding: 120px 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%
}

@media (max-width:1200px) {
  .page-title {
    padding: 100px 0
  }
}

@media (max-width:767px) {
  .page-title {
    padding: 80px 0
  }
}

.page-title h1 {
  font-weight: 700;
  margin-bottom: 15px;
  color: #fff
}

@media (max-width:991px) {
  .page-title h1 {
    font-size: 22px
  }
}

@media (max-width:767px) {
  .navbar-brand {
    height: 28px
  }
}

.breadcrumb {
  margin: 0;
  padding: 0;
  list-style: none;
  background: none;
  border-radius: 0
}

.breadcrumb li {
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  font-weight: 400;
  position: relative;
  color: #fff
}

.breadcrumb li+li {
  padding-left: 17px;
  margin-left: 17px
}

.breadcrumb li+li:after {
  content: "";
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: currentColor;
  opacity: .85;
  left: -2px;
  top: 0;
  bottom: 0;
  margin: auto
}

.breadcrumb li a {
  color: #dc965a
}

.px-btn {
  padding: 0 25px;
  line-height: 46px;
  display: inline-block;
  background: none;
  border: none;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none !important;
  position: relative;
  z-index: 1;
  overflow: hidden;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, .25);
  cursor: pointer !important
}

.px-btn.btn-md {
  padding: 3px 30px;
  font-size: 18px;
  letter-spacing: 1px
}

@media screen and (max-width:767px) {
  .px-btn.btn-md {
    padding: 0 25px
  }
}

.px-btn.px-btn-theme {
  background: #dc965a;
  color: #fff
}

.px-btn.px-btn-theme:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: auto;
  top: 0;
  width: 0;
  transition: all .35s ease;
  background: #1c1d24;
  z-index: -1
}

.px-btn.px-btn-theme:hover {
  color: #fff
}

.px-btn.px-btn-theme:hover:after {
  width: 100%;
  left: auto;
  right: 0
}

.px-btn.px-btn-white {
  background: #fff;
  border-color: #fff;
  color: #1c1d24
}

.px-btn.px-btn-white:hover {
  background: transparent;
  color: #fff
}

.gray-bg {
  background: #fff5ec
}

.theme-bg {
  background: #dc965a
}

.dark-bg {
  background: #1c1d24
}

.color-dark {
  color: #1c1d24 !important
}

.color-white {
  color: #fff
}

.color-theme {
  color: #dc965a
}

.main-header.header-fluid {
  border: none;
  padding: 10px 65px
}

@media (max-width:991px) {
  .main-header.header-fluid {
    padding: 0 15px
  }
}

@media (max-width:767px) {
  .main-header.header-fluid {
    padding: 0
  }
}

.main-header.header-fluid .container-fluid {
  padding: 0
}

.home-dark .main-header.header-fluid .navbar-nav>li {
  border: none;
  padding: 0 10px
}

.navbar-toggler,
.toggler-menu {
  width: 40px;
  height: 40px;
  position: relative;
  border-radius: 0;
  padding: 0;
  margin: 0 20px 0 15px;
  background: transparent;
  border: none;
  cursor: pointer !important
}

.navbar-toggler span,
.toggler-menu span {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 25px;
  height: 2px;
  margin: auto;
  background: #fff;
  color: #fff;
  transition: .3s
}

.main-header .navbar-brand {
  max-width: 75px;
  margin: 19px 20px;
  transition: .3s
}

@media (min-width:992px) {
  .main-header {
    position: fixed;
    left: 0;
    z-index: 111;
    width: 100%;
    top: 0;
    border-bottom: 1px solid hsla(0, 0%, 100%, .2);
    transition: all .3s ease
  }

  .main-header.fixed-header {
    box-shadow: 0 0 30px rgba(0, 0, 0, .1);
    background: #fff
  }

  .main-header .navbar {
    padding: 0
  }

  .main-header .navbar-nav>li {
    border-right: 1px solid hsla(0, 0%, 100%, .2);
    padding: 0 20px
  }

  .fixed-header .main-header .navbar-nav>li {
    border-right: 1px solid rgba(28, 29, 36, .1)
  }

  .main-header .navbar-nav>li:first-child {
    border-left: 1px solid hsla(0, 0%, 100%, .2)
  }

  .fixed-header .main-header .navbar-nav>li:first-child {
    border-left: 1px solid rgba(28, 29, 36, .1)
  }

  .main-header .navbar-nav>li>a {
    padding: 0 10px;
    line-height: 70px;
    color: hsla(0, 0%, 100%, .9);
    font-weight: 700;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: .05rem;
    transition: .3s
  }

  .main-header .navbar-nav>li>a.current,
  .main-header .navbar-nav>li>a:hover {
    color: #fff
  }

  .fixed-header .main-header .navbar-nav>li>a {
    color: #1c1d24
  }

  .fixed-header .main-header .navbar-nav>li>a.current,
  .main-header .navbar-nav>li.current a {
    color: #dc965a
  }
}

.header-transparent {
  display: flex;
  align-items: center;
  justify-content: space-between
}

.header-transparent .navbar-nav {
  display: flex;
  align-items: center;
  margin: 0
}

.header-transparent .navbar-nav li {
  list-style: none
}

.header-transparent .navbar-nav li a {
  text-decoration: none
}

@media (min-width:992px) {
  .header-transparent .navbar-toggler {
    display: none
  }
}

@media (max-width:991px) {
  .header-transparent .mobile-menu {
    position: absolute;
    height: 100vh;
    width: 300px;
    left: 0;
    top: 0;
    z-index: 99;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    transition: .5s;
    background: #1c1d24
  }

  .header-transparent .mobile-menu.active {
    -webkit-transform: translateX(0);
    transform: translateX(0)
  }

  .header-transparent .navbar-nav {
    padding: 50px 10px;
    display: block;
    height: 100%;
    align-items: center;
    margin: 0
  }
}

@media (max-width:350px) {
  .header-transparent .mobile-menu {
    width: 260px
  }
}

.hamburger span:first-child {
  top: -17px
}

.hamburger span:nth-child(2) {
  top: 2px
}

.hamburger span:nth-child(3) {
  top: auto;
  bottom: 10px
}

.hamburger.active span:first-child {
  top: 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg)
}

.hamburger.active span:nth-child(2) {
  left: 50%;
  width: 0
}

.hamburger.active span:nth-child(3) {
  top: 10px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg)
}

@media (max-width:991px) {
  .main-header {
    position: fixed;
    left: 0;
    z-index: 111;
    width: 100%;
    top: 0;
    border-bottom: 1px solid hsla(0, 0%, 100%, .2);
    box-shadow: 0 0 30px rgba(0, 0, 0, .1)
  }

  .main-header .container {
    max-width: 100%;
    padding: 0
  }

  .fixed-header .main-header {
    background: #1c1d24
  }

  .main-header .navbar {
    padding: 0
  }

  .main-header .navbar-nav {
    background: #1c1d24;
    position: relative
  }

  .main-header .navbar-nav>li>a {
    padding: 12px 20px;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: .05rem;
    color: #fff;
    display: block
  }

  .main-header .navbar-nav>li>a.current {
    color: #dc965a
  }

  .main-header.fixed-header {
    border-bottom-color: #1c1d24
  }
}

.fixed-header .navbar-nav>li {
  border-right: 1px solid rgba(15, 10, 10, .1)
}

.fixed-header .navbar-nav>li>a {
  color: #1c1d24
}

.fixed-header .navbar-nav>li>a:hover {
  color: #dc965a
}

@media (max-width:991px) {
  .fixed-header .navbar-nav>li>a {
    color: #fff
  }

  .fixed-header .navbar-nav>li>a:hover {
    color: #dc965a
  }
}

.fixed-header .navbar-brand {
  -webkit-filter: invert(1);
  filter: invert(1)
}

@media (max-width:991px) {
  .fixed-header .navbar-brand {
    -webkit-filter: invert(0);
    filter: invert(0)
  }
}

@media (max-width:991px) {
  .fixed-header .header-transparent {
    background: #1c1d24
  }
}

.fixed-header.main-header .navbar-nav>li.current a {
  color: #dc965a
}

.sidebar-bottom-closer {
  display: none
}

@media (max-width:991px) {
  .sidebar-bottom-closer {
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    margin: 0;
    color: #fff;
    border: none;
    width: 100%;
    background: #dc965a;
    font-size: 18px;
    z-index: 5;
    cursor: pointer !important;
    padding: 8px 5px;
    letter-spacing: 1px
  }

  .sidebar-bottom-closer.dark-bg {
    background: #333
  }

  .mobile-menu.active ul li:first-child {
    -webkit-animation: customThree .6s ease-in-out 0s 1 normal none running;
    animation: customThree .6s ease-in-out 0s 1 normal none running
  }

  .mobile-menu.active ul li:nth-child(2) {
    -webkit-animation: customThree .7s ease-in-out 0s 1 normal none running;
    animation: customThree .7s ease-in-out 0s 1 normal none running
  }

  .mobile-menu.active ul li:nth-child(3) {
    -webkit-animation: customThree .8s ease-in-out 0s 1 normal none running;
    animation: customThree .8s ease-in-out 0s 1 normal none running
  }

  .mobile-menu.active ul li:nth-child(4) {
    -webkit-animation: customThree .9s ease-in-out 0s 1 normal none running;
    animation: customThree .9s ease-in-out 0s 1 normal none running
  }

  .mobile-menu.active ul li:nth-child(5) {
    -webkit-animation: customThree 1s ease-in-out 0s 1 normal none running;
    animation: customThree 1s ease-in-out 0s 1 normal none running
  }

  .mobile-menu.active ul li:nth-child(6) {
    -webkit-animation: customThree 1.1s ease-in-out 0s 1 normal none running;
    animation: customThree 1.1s ease-in-out 0s 1 normal none running
  }

  .mobile-menu.active .close_menu {
    -webkit-animation: customThree 1.2s ease-in-out 0s 1 normal none running;
    animation: customThree 1.2s ease-in-out 0s 1 normal none running
  }
}

@-webkit-keyframes customThree {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px)
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none
  }
}

@keyframes customThree {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px)
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none
  }
}

.footer {
  padding: 12px 0;
  background: #1c1d24
}

.theme-light .footer {
  background: #fff;
  border-top: 1px solid rgba(28, 29, 36, .1)
}

.footer .nav a {
  color: #fff;
  font-size: 14px;
  margin-right: 17px
}

.theme-light .footer .nav a {
  color: #1c1d24
}

.footer .nav a:hover {
  color: #dc965a
}

.footer p {
  color: #fff;
  font-size: 14px;
  margin: 0
}

.theme-light .footer p {
  color: #1c1d24
}

.footer p a {
  color: #fff
}

.footer p a:hover {
  color: #dc965a
}

@media screen and (min-width:768px) {
  .text-md-left {
    text-align: left
  }

  .text-md-right {
    text-align: right
  }
}

.blog-post .blog-img {
  position: relative;
  overflow: hidden
}

.blog-post .blog-img img {
  transition: all .35s ease;
  -webkit-transform: scale(1);
  transform: scale(1);
  cursor: pointer
}

.blog-post:hover .blog-img img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1)
}

.blog-post .data {
  position: absolute;
  top: 20px;
  left: 10px;
  background: #fff;
  padding: 8px 10px;
  line-height: 1;
  text-align: center;
  z-index: 1
}

.blog-post .data span {
  width: 100%;
  display: inline-block;
  font-weight: 600;
  font-size: 25px;
  line-height: 1;
  margin: 0;
  color: #1c1d24
}

.blog-post .data small {
  font-size: 13px;
  text-transform: uppercase;
  color: #5e6472;
  margin-top: 7px;
  display: inline-block;
  width: 100%;
  vertical-align: top
}

.blog-post .blog-info {
  padding: 20px;
  margin: -30px 10px 0;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, .25);
  position: relative;
  overflow: hidden;
  background: #fff
}

.blog-post .blog-info h6 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 15px;
  line-height: 1.3;
  color: #1c1d24;
  cursor: pointer;
  transition: .3s
}

.blog-post .blog-info h6:hover {
  color: #dc965a
}

.blog-post .blog-info p {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin: 0 0 12px
}

.blog-post .blog-info .btn-bar {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: .1rem;
  transition: .3s;
  cursor: pointer
}

.blog-post .blog-info .btn-bar:hover {
  color: #dc965a;
  text-decoration: underline
}

.blog-listing {
  padding-top: 30px;
  padding-bottom: 30px
}

.blog-listing .pagination {
  margin-top: 30px
}

.blog-listing .blog-post {
  border: 1px solid #eee;
  box-shadow: 0 4px 30px #ddd
}

.blog-listing .blog-post .blog-info {
  margin: 0;
  box-shadow: none
}

.blog-listing.main-blog-wrapper {
  padding: 44px 0
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none
}

.pagination .page-item {
  border: none;
  box-shadow: none;
  color: #1c1d24;
  margin: 0 5px;
  border-radius: 50px;
  padding: 0;
  width: 35px;
  height: 35px;
  text-align: center;
  line-height: 35px;
  background: #ddd;
  display: block;
  cursor: pointer;
  transition: .3s
}

.pagination .page-item:hover {
  background: #dc965a;
  border-color: #dc965a;
  color: #fff
}

.pagination .page-item.disabled {
  opacity: .6;
  pointer-events: none
}

.pagination .page-item.active {
  background: #dc965a;
  border-color: #dc965a;
  color: #fff
}

.blog-aside {
  border-left: 1px solid #eee
}

.blog-aside .widget {
  margin-top: 15px;
  margin-bottom: 25px;
  width: 100%;
  display: inline-block;
  vertical-align: top
}

.blog-aside .widget:last-child {
  margin-bottom: 0
}

.blog-aside .widget-title {
  border-bottom: 1px solid #eee;
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 25px;
  padding-left: 25px
}

.blog-aside .widget-title:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 25px;
  width: 50px;
  height: 2px;
  background: #dc965a
}

.blog-aside .widget-title h3 {
  font-size: 20px;
  font-weight: 700;
  color: #1c1d24;
  margin: 0
}

.blog-aside .widget-body {
  padding-left: 25px
}

.blog-aside .widget-author .media {
  margin-bottom: 15px
}

.blog-aside .widget-author p {
  font-size: 16px;
  margin: 0
}

.blog-aside .widget-author .avatar {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  overflow: hidden
}

.blog-aside .widget-author h6 {
  font-weight: 600;
  color: #1c1d24;
  font-size: 18px;
  margin: 0;
  padding-left: 20px
}

.blog-aside .post-aside {
  margin-bottom: 15px
}

.blog-aside .post-aside .post-aside-title h5 {
  margin: 0
}

.blog-aside .post-aside .post-aside-title a {
  font-size: 18px;
  color: #1c1d24;
  font-weight: 500
}

.blog-aside .post-aside .post-aside-title a:hover {
  color: #dc965a
}

.blog-aside .post-aside .post-aside-meta {
  padding-bottom: 10px
}

.blog-aside .post-aside .post-aside-meta span {
  color: #5e6472;
  font-size: 12px;
  text-transform: uppercase;
  display: inline-block;
  margin-right: 10px
}

.blog-aside .latest-post-aside+.latest-post-aside {
  border-top: 1px solid #eee;
  padding-top: 15px;
  margin-top: 15px
}

.blog-aside .latest-post-aside .lpa-right {
  width: 90px
}

.blog-aside .latest-post-aside .lpa-left {
  padding-right: 15px
}

.blog-aside .latest-post-aside .lpa-title h5 {
  margin: 0;
  font-size: 15px;
  line-height: 22px
}

.blog-aside .latest-post-aside .lpa-title a {
  color: #1c1d24;
  font-weight: 600
}

.blog-aside .latest-post-aside .lpa-title a:hover {
  color: #dc965a
}

.blog-aside .latest-post-aside .lpa-meta span {
  color: #5e6472;
  font-size: 12px;
  text-transform: uppercase;
  display: inline-block;
  margin-right: 10px
}

.tag-cloud a {
  padding: 4px 15px;
  font-size: 13px;
  color: #1c1d24;
  background: #fff5ec;
  border-radius: 3px;
  margin-right: 4px;
  margin-bottom: 4px;
  font-weight: 500;
  display: inline-block
}

.tag-cloud a:hover {
  background: #dc965a;
  color: #fff
}

.blog-single {
  padding-top: 30px;
  padding-bottom: 30px
}

.article {
  margin: 15px 0 30px
}

.article .article-title {
  padding: 15px 0 20px
}

.article .article-title h6 {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 20px
}

.article .article-title h6 a {
  text-transform: uppercase;
  color: #dc965a;
  border-bottom: 1px solid #dc965a
}

.article .article-title h2 {
  color: #1c1d24;
  font-weight: 700
}

@media (max-width:991px) {
  .article .article-title h2 {
    font-size: 24px
  }
}

.article .article-title .media {
  padding-top: 15px;
  border-bottom: 1px dashed #ddd;
  padding-bottom: 20px
}

.article .article-title .media .avatar {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  overflow: hidden
}

.article .article-title .media .media-body {
  padding-left: 8px
}

.article .article-title .media .media-body label {
  font-weight: 500;
  color: #dc965a;
  margin: 0
}

.article .article-title .media .media-body span {
  display: block;
  font-size: 12px
}

.article .article-content h1,
.article .article-content h2,
.article .article-content h3,
.article .article-content h4,
.article .article-content h5,
.article .article-content h6 {
  color: #1c1d24;
  font-weight: 600;
  margin-bottom: 15px
}

.article .article-content blockquote {
  width: 100%;
  padding: 30px;
  margin: 20px 0 30px;
  background: #fff5ec
}

.article .article-content blockquote p {
  font-size: 20px;
  font-weight: 500;
  margin: 0
}

.article .article-content blockquote .blockquote-footer {
  color: #1c1d24;
  font-size: 16px;
  padding-top: 20px
}

.article .article-content blockquote .blockquote-footer cite {
  font-weight: 600
}

.article .tag-cloud {
  padding-top: 10px
}

.article-comment {
  border: 1px solid #eee;
  overflow: hidden;
  background: #fff;
  padding: 30px;
  margin-bottom: 15px
}

.article-comment h4 {
  color: #1c1d24;
  font-weight: 700;
  margin-bottom: 25px;
  font-size: 22px
}

.portfolio-filter-01 {
  padding-top: 15px;
  padding-bottom: 15px
}

.portfolio-filter-01 .filter li.react-tabs__tab {
  list-style-type: none;
  cursor: pointer;
  margin: 0 15px;
  color: #1c1d24;
  position: relative;
  padding: 5px 0;
  font-weight: 600;
  font-size: 16px;
  line-height: normal
}

@media (max-width:767px) {
  .portfolio-filter-01 .filter li.react-tabs__tab {
    margin: 0 10px;
    font-size: 15px
  }
}

.portfolio-filter-01 .filter li.react-tabs__tab:after {
  content: "";
  width: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  height: 2px;
  background: #dc965a;
  position: absolute;
  transition: all .55s ease
}

.portfolio-filter-01 .filter li.react-tabs__tab:last-child {
  margin-right: 0
}

.portfolio-filter-01 .filter li.react-tabs__tab:first-child {
  margin-left: 0
}

.portfolio-filter-01 .filter li.react-tabs__tab.react-tabs__tab--selected:after {
  width: 100%
}

.portfolio-box-01 {
  background: #fff;
  padding: 20px;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, .25);
  margin: 15px 0;
  position: relative
}

.portfolio-box-01 .portfolio-img {
  position: relative
}

.portfolio-box-01 img {
  margin: auto;
  display: block
}

.portfolio-box-01 .portfolio-info {
  padding-bottom: 20px
}

.portfolio-box-01 .portfolio-info h5 {
  font-weight: 600;
  color: #1c1d24;
  margin: 0
}

.portfolio-box-01 .portfolio-info h5 a {
  color: #1c1d24
}

.portfolio-box-01 .portfolio-info h5:hover a {
  color: #dc965a;
  letter-spacing: .5px
}

.portfolio-box-01 .gallery-link {
  display: block;
  position: relative;
  overflow: hidden;
  cursor: zoom-in !important
}

.portfolio-box-01 .gallery-link:after {
  content: "";
  position: absolute;
  bottom: 0;
  top: auto;
  left: 0;
  right: 0;
  height: 0;
  width: 100%;
  transition: all .55s ease;
  background: rgba(220, 150, 90, .8);
  pointer-events: none
}

.portfolio-box-01 .gallery-link img {
  transition: .6s
}

.portfolio-box-01 .portfolio-icon {
  position: absolute;
  bottom: 16px;
  right: 16px;
  transition: all .55s ease;
  opacity: 0;
  width: 50px;
  height: 50px;
  z-index: 1;
  display: inline-block;
  vertical-align: top;
  line-height: 55px;
  background: #fff;
  text-align: center;
  color: #dc965a;
  text-decoration: none;
  font-size: 26px
}

.portfolio-box-01 .portfolio-icon span {
  font-weight: 600
}

.portfolio-box-01:hover h5 a {
  color: #dc965a
}

.portfolio-box-01:hover .gallery-link:after {
  bottom: auto;
  top: 0;
  height: 100%
}

.portfolio-box-01:hover .gallery-link img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1)
}

.portfolio-box-01:hover .portfolio-info {
  position: relative;
  z-index: 1
}

.portfolio-box-01:hover .portfolio-icon,
.portfolio-box-01:hover .portfolio-info {
  -webkit-animation: slideTop .7s ease-in-out 0s 1 normal none running;
  animation: slideTop .7s ease-in-out 0s 1 normal none running
}

.portfolio-box-01:hover .portfolio-icon {
  opacity: 1
}

.filter {
  margin-bottom: 25px;
  padding-left: 0;
  flex-wrap: wrap
}

@-webkit-keyframes slideTop {
  0% {
    opacity: 0;
    -webkit-transform: translateY(15px);
    transform: translateY(15px)
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none
  }
}

@keyframes slideTop {
  0% {
    opacity: 0;
    -webkit-transform: translateY(15px);
    transform: translateY(15px)
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none
  }
}

@-webkit-keyframes slideBottom {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px)
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none
  }
}

@keyframes slideBottom {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px)
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none
  }
}

@-webkit-keyframes slideLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-25px);
    transform: translateX(-25px)
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none
  }
}

@keyframes slideLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-25px);
    transform: translateX(-25px)
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none
  }
}

.SRLImage,
.SRLThumbnailsContainer a {
  background-color: #fff !important
}

.section {
  padding: 100px 0;
  position: relative
}

@media (max-width:991px) {
  .section {
    padding-top: 80px;
    padding-bottom: 80px
  }
}

.after-left-section {
  position: relative
}

.after-left-section:after {
  content: "";
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  width: 40%;
  background: #dc965a;
  z-index: -1
}

@media (max-width:991px) {
  .after-left-section:after {
    display: none
  }
}

.after-left-section .container {
  position: relative;
  z-index: 2
}

.section-title {
  padding-bottom: 40px
}

.section-title h3 {
  font-size: 45px;
  color: #1c1d24;
  font-weight: 800;
  position: relative;
  margin: 0 0 15px;
  padding: 0 0 15px
}

@media (max-width:767px) {
  .section-title h3 {
    font-size: 40px
  }
}

.section-title h3:after {
  content: "";
  position: absolute;
  width: 40px;
  height: 3px;
  background: #dc965a;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto
}

.section-title p {
  margin: 0;
  font-size: 17px
}

.sm-title {
  margin-bottom: 30px
}

.sm-title h4 {
  color: #1c1d24;
  font-size: 24px;
  position: relative;
  padding: 0 0 15px;
  margin: 0;
  font-weight: 700
}

.sm-title h4:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 30px;
  height: 3px;
  background: #dc965a
}

.sm-title p {
  margin: 15px 0 0
}

.video-banner .btn-bar {
  margin-top: 35px
}

@media screen and (max-width:767px) {
  .video-banner .btn-bar {
    margin-top: 25px
  }
}

.overlay:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .25);
  z-index: 3
}

.overlay>div {
  position: relative;
  z-index: 5
}

.overlay.overlay-75:before {
  background-color: rgba(0, 0, 0, 0.75)
}

.overlay.overlay-45:before {
  background-color: rgba(0, 0, 0, 0.75)
}

.home-banner {
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%
}

.home-banner.slider-three-animation {
  background-position: 100%
}

@media (max-width:767px) {
  .home-banner {
    background-position: 70% 100%
  }

  .home-banner.slider-three-animation {
    background-position: 80% 100%
  }
}

.home-banner.video-banner {
  position: relative;
  z-index: 1
}

.home-banner.video-banner.slider-three-animation {
  z-index: auto
}

.home-banner .particles-box {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: .2;
  pointer-events: none
}

.home-banner .hb-avatar {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  width: 50%;
  text-align: center
}

.home-banner .hb-avatar img {
  height: 100%;
  object-fit: cover
}

.home-banner .top-style {
  position: absolute;
  width: 400px;
  top: -100px;
  left: -50px
}

@media (max-width:767px) {
  .home-banner .top-style {
    width: 200px;
    top: -100px;
    left: -50px
  }
}

.home-banner .top-style svg {
  color: #dc965a
}

.home-banner .bottom-style {
  position: absolute;
  width: 400px;
  bottom: -100px;
  right: -50px
}

.home-banner .bottom-style svg {
  color: #dc965a
}

@media (max-width:767px) {
  .home-banner .bottom-style {
    width: 200px;
    bottom: -50px;
    right: -25px
  }
}

.home-banner .full-screen {
  min-height: 100vh;
  padding-top: 120px;
  padding-bottom: 90px
}

@media screen and (max-width:575px) {
  .home-banner .full-screen {
    min-height: calc(100vh - 56px) !important;
    padding: 90px 0
  }
}

@supports (-webkit-appearance:none) {
  .os-android .home-banner .full-screen {
    min-height: calc(100vh - 56px) !important
  }
}

.home-banner .text-left {
  text-align: left
}

@media screen and (max-width:575px) {
  .home-banner .text-left {
    text-align: center
  }
}

.home-banner .hb-typo h6 {
  font-size: 25px;
  margin-bottom: 15px;
  color: #fff
}

@media (max-width:767px) {
  .home-banner .hb-typo h6 {
    font-size: 18px
  }
}

.home-banner .hb-typo h1 {
  font-size: 80px;
  color: #fff;
  font-weight: 700;
  margin-bottom: 15px
}

@media (max-width:991px) {
  .home-banner .hb-typo h1 {
    font-size: 60px
  }
}

@media (max-width:767px) {
  .home-banner .hb-typo h1 {
    font-size: 45px
  }

  .home-banner .hb-typo h1 br {
    display: none
  }
}

.home-banner .hb-typo h2 {
  font-size: 38px;
  color: #fff;
  font-weight: 500;
  margin: 0
}

@media (max-width:767px) {
  .home-banner .hb-typo h2 {
    font-size: 22px
  }
}

.home-banner .social-icons {
  position: absolute;
  right: 40px;
  bottom: 30px
}

@media (max-width:767px) {
  .home-banner .social-icons {
    left: 40px;
    margin: auto
  }
}

.home-banner .social-icons a {
  width: 35px;
  height: 35px;
  text-align: center;
  background: #fff;
  border-radius: 50%;
  line-height: 35px;
  color: #1c1d24;
  margin-right: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: .4s ease-in-out
}

.home-banner .social-icons a:hover {
  background: #dc965a;
  color: #fff;
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px)
}

.home-banner .scroll-bottom {
  position: absolute;
  bottom: 30px;
  left: 50px;
  z-index: 5
}

.home-banner .scroll-bottom.center-position {
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%)
}

@media (max-width:767px) {
  .home-banner .scroll-bottom {
    bottom: 0;
    display: none
  }
}

.home-banner .scroll-bottom a {
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: .2rem;
  transition: .3s;
  cursor: pointer !important
}

.home-banner .scroll-bottom a:hover {
  color: #dc965a;
  letter-spacing: .3rem
}

.ht-list span {
  background: hsla(0, 0%, 100%, .1);
  padding: 7px 20px;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  color: #fff;
  border-radius: 30px;
  letter-spacing: 1px;
  display: inline-flex;
  margin: 0 3px 8px
}

.about-section .container {
  position: relative;
  z-index: 1
}

.about-section .img-box {
  position: relative;
  overflow: hidden;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, .25);
  background: #fff5ec;
  padding: 10px
}

.about-section .img-box img {
  position: relative
}

.about-section .typo-box {
  padding-left: 40px
}

@media (max-width:991px) {
  .about-section .typo-box {
    padding-top: 15px;
    padding-left: 0
  }
}

.about-section .typo-box h3 {
  font-size: 40px;
  font-weight: 700;
  color: #1c1d24;
  margin-bottom: 15px
}

@media (max-width:767px) {
  .about-section .typo-box h3 {
    font-size: 30px
  }
}

.about-section .typo-box h5 {
  font-size: 18px;
  font-weight: 600;
  color: #1c1d24;
  margin-bottom: 20px
}

.about-section .typo-box .lead {
  font-weight: 400
}

@media (max-width:767px) {
  .about-section .typo-box .lead {
    font-size: 17px
  }
}

.about-section .typo-box .btn-bar {
  padding-top: 12px
}

.about-me .about-list {
  padding: 10px 0 15px
}

.about-me .media {
  padding: 10px 0;
  line-height: normal
}

.about-me .media label {
  margin: 0;
  color: #090a0c;
  font-weight: 700;
  font-size: 15px;
  flex: 0 0 90px;
  border-right: 1px solid #c1c1c1;
  max-width: 90px
}

.about-me .media p {
  margin-bottom: 0;
  padding-left: 15px;
  font-size: 15px
}

.feature-box-01 {
  background: #fff;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, .25);
  padding: 15% 12%;
  position: relative;
  z-index: 1
}

.feature-box-01:after {
  content: "";
  position: absolute;
  bottom: 0;
  top: auto;
  left: 0;
  right: 0;
  height: 0;
  width: 100%;
  transition: .5s ease-in-out;
  background: #dc965a;
  opacity: .8;
  z-index: -1
}

.feature-box-01 .icon {
  width: 60px;
  height: 60px;
  line-height: 60px;
  font-size: 50px;
  margin-bottom: 20px;
  color: #dc965a;
  transition: .5s ease-in-out
}

.feature-box-01 h5 {
  color: #1c1d24;
  font-weight: 600;
  transition: .5s ease-in-out
}

.feature-box-01 p {
  margin: 0;
  transition: .5s ease-in-out
}

.feature-box-01:hover {
  color: hsla(0, 0%, 100%, .7)
}

.feature-box-01:hover .icon,
.feature-box-01:hover h5,
.feature-box-01:hover p {
  color: #fff;
  -webkit-animation: slideTop .7s ease-in-out 0s 1 normal none running !important;
  animation: slideTop .7s ease-in-out 0s 1 normal none running !important
}

.feature-box-01:hover:after {
  bottom: auto;
  top: 0;
  height: 100%
}

.feature-section .feature-box-01:hover {
  color: hsla(0, 0%, 100%, .7)
}

.feature-section .feature-box-01:hover .icon,
.feature-section .feature-box-01:hover h5,
.feature-section .feature-box-01:hover p {
  -webkit-animation: none !important;
  animation: none !important
}

@media screen and (max-width:991px) {
  .services-inner-section .intro-text {
    margin-top: 30px
  }
}

.services-inner-section .intro-text h6 {
  font-weight: 700;
  color: #dc965a;
  font-size: 18px;
  margin-bottom: 15px
}

.services-inner-section .intro-text h2 {
  font-weight: 700;
  color: #0f0a0a;
  font-size: 35px;
  margin-bottom: 20px
}

.services-details-section .card {
  border: 1px solid #eee
}

.services-details-section .card .card-header {
  border-bottom: none;
  padding: 0
}

.services-details-section .card .card-header .h5 {
  border-bottom: 1px solid #eee;
  width: 100%;
  display: inline-block;
  vertical-align: top
}

.feature-box-02 {
  background: #fff;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, .25);
  padding: 40px 30px;
  position: relative;
  transition: all .35s ease;
  top: 0;
  z-index: 1
}

.feature-box-02 .icon {
  width: 60px;
  height: 60px;
  line-height: 60px;
  font-size: 50px;
  margin-bottom: 20px;
  color: #dc965a;
  transition: .5s ease-in-out
}

.feature-box-02:after {
  top: 0;
  right: auto;
  border-top: 5px solid #dc965a;
  border-bottom: 5px solid #dc965a;
  width: 0
}

.feature-box-02:after,
.feature-box-02:before {
  content: "";
  left: 0;
  bottom: 0;
  position: absolute;
  transition: all .35s ease;
  opacity: 0;
  pointer-events: none
}

.feature-box-02:before {
  top: auto;
  right: 0;
  border-left: 5px solid #dc965a;
  border-right: 5px solid #dc965a;
  height: 0
}

.feature-box-02 h6 {
  font-weight: 700;
  position: relative;
  color: #dc965a;
  padding: 5px 0;
  font-size: 14px;
  margin-bottom: 15px;
  z-index: 1
}

.feature-box-02 h6:after {
  content: "";
  position: absolute;
  left: -30px;
  top: 0;
  width: 5px;
  height: 100%;
  background: #dc965a
}

.feature-box-02 h5 {
  font-weight: 700;
  color: #0f0a0a;
  margin-bottom: 15px
}

.feature-box-02 p {
  margin: 0
}

.feature-box-02:hover {
  top: -5px
}

.feature-box-02:hover h6:after {
  background: #fff
}

.feature-box-02:hover:after {
  width: 100%;
  opacity: 1;
  left: auto;
  right: 0
}

.feature-box-02:hover:before {
  height: 100%;
  opacity: 1;
  top: 0;
  bottom: auto
}

.author-signeture {
  -webkit-filter: opacity(.9);
  filter: opacity(.9)
}

.feature-box-03 {
  background: #fff;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, .25);
  padding: 40px 30px;
  position: relative;
  transition: all .35s ease;
  z-index: 1
}

.feature-box-03:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: auto;
  right: 0;
  width: 0;
  height: 3px;
  background: #dc965a;
  transition: all .35s ease
}

.feature-box-03 .icon {
  background: #dc965a;
  color: #fff;
  width: 80px;
  height: 80px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  border-radius: 40% 30% 50% 30%/30% 40% 30% 30%;
  margin-bottom: 20px;
  box-shadow: -2px 3px 0 1px #ddd;
  position: relative;
  top: 0;
  left: 0;
  transition: top .25s ease, left .25s ease, box-shadow .25s ease
}

.feature-box-03 h5 {
  font-weight: 700;
  color: #0f0a0a;
  font-size: 17px
}

.feature-box-03 p {
  margin: 0
}

.feature-box-03:hover:after {
  width: 100%;
  left: 0;
  right: auto
}

.feature-box-03:hover .icon {
  box-shadow: 0 0 0 0 #ddd;
  top: 2px;
  left: -3px
}

.skill-section .aducation-box {
  margin: 0;
  list-style: none;
  padding: 25px;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, .25)
}

.skill-section .aducation-box li+li {
  padding-top: 25px;
  margin-top: 20px;
  border-top: 1px dashed hsla(0, 0%, 100%, .2)
}

.skill-section .aducation-box span {
  display: inline-block;
  vertical-align: top;
  margin-bottom: 16px;
  color: #fff;
  padding: 4px 12px;
  border-radius: 30px;
  font-size: 12px;
  line-height: 1
}

.skill-section .aducation-box h6 {
  font-weight: 600;
  font-size: 18px;
  color: #fff
}

.skill-section .aducation-box p {
  margin: 0;
  color: hsla(0, 0%, 100%, .8)
}

.skill-section .skill-box {
  min-height: calc(100% - 73px);
  padding: 25px;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, .25)
}

.skill-lt:not(:first-child) {
  margin-top: 30px
}

.skill-lt h6 {
  font-size: 16px;
  margin: 0 0 8px;
  color: #1c1d24;
  line-height: 28px;
  font-weight: 600
}

.skill-lt .skill-bar {
  position: relative;
  background: #1c1d24
}

.skill-lt .skill-bar .skill-bar-in {
  width: 80px;
  height: 10px;
  position: relative;
  transition: all .55s ease
}

.skill-lt .skill-bar .skill-bar-in span {
  position: absolute;
  right: 0;
  bottom: 15px;
  background: #1c1d24;
  padding: 2px 6px;
  color: #fff;
  font-size: 10px;
  font-weight: 600
}

.dark-theme .skill-lt .skill-bar .skill-bar-in span {
  color: #1c1d24;
  background: #fff
}

.skill-lt .skill-bar .skill-bar-in span:after {
  content: "";
  position: absolute;
  bottom: -8px;
  right: 0;
  border-left: 7px solid transparent;
  border-top: 8px solid #1c1d24
}

.dark-theme .skill-lt .skill-bar .skill-bar-in span:after {
  border-top: 8px solid #fff
}

.skill-lt.md .skill-bar .skill-bar-in {
  height: 8px
}

.skill-lt.lg .skill-bar .skill-bar-in {
  height: 10px
}

.skill-lt.light .skill-bar {
  background: rgba(19, 20, 22, .5)
}

.testmonial-box {
  margin: 20px 0 30px
}

.testmonial-box .avatar {
  width: 30%;
  padding: 0 15px 15px;
  position: relative
}

.testmonial-box .avatar:after {
  content: "";
  position: absolute;
  bottom: 5px;
  top: 15px;
  left: 5px;
  right: 30px;
  z-index: -1;
  border: 2px solid #dc965a
}

.testmonial-box .testmonial-text {
  padding-left: 20px
}

.testmonial-box .testmonial-text p {
  margin: 0
}

.testmonial-box .testmonial-info {
  font-size: 12px;
  padding-top: 10px
}

.testmonial-box .testmonial-info h6 {
  font-size: 15px;
  font-weight: 600;
  color: #1c1d24;
  margin: 0 0 10px
}

.testmonial-box .testmonial-info .rating-value {
  display: inline-block;
  vertical-align: middle;
  background: #fff;
  border-radius: 30px;
  padding: 0 10px;
  box-shadow: 1px 1px 1px 1px rgba(28, 29, 36, .1);
  font-size: 9px
}

.testmonial-box .testmonial-info .rating-value i {
  color: #ffc800;
  margin-right: 3px
}

.slick-slide>div {
  margin: 0 20px
}

.slick-list {
  margin: 0 -20px
}

.media {
  display: flex !important;
  align-items: center;
}

.media-body {
  flex: 1 1
}

.slick-dots {
  bottom: -25px
}

.slick-dots li {
  margin: 0 2px
}

.slick-dots li button {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: relative
}

.slick-dots li button:before {
  font-size: 0;
  opacity: 1;
  border: 1px solid #dc965a;
  border-radius: 50%
}

.slick-dots li button:after,
.slick-dots li button:before {
  content: "";
  width: 100%;
  height: 100%;
  transition: .3s ease-in-out
}

.slick-dots li button:after {
  position: absolute;
  left: 0;
  top: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
  background: #dc965a;
  border-radius: inherit;
  z-index: 5
}

.slick-dots li.slick-active button:after {
  -webkit-transform: scale(1);
  transform: scale(1)
}

.slick-arrow {
  display: none !important
}

.g-map {
  background: #fff;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, .25);
  padding: 15px;
  margin-top: 50px
}

.g-map .ratio {
  background: #efefef
}

.g-map iframe {
  width: 100%;
  height: 350px;
  border: none !important
}

.contct-form {
  background: #fff;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, .25);
  position: relative;
  overflow: hidden;
  padding: 35px
}

@media (max-width:767px) {
  .contct-form {
    padding: 25px 15px
  }
}

@media screen and (max-width:991px) {
  .contct-form {
    margin-bottom: 20px
  }
}

.contct-form .form-group {
  margin-bottom: 30px;
  position: relative
}

.contct-form .form-group .form-label {
  position: absolute;
  top: 9px;
  margin: 0;
  left: 2px;
  font-weight: 400;
  font-size: 14px;
  color: #777;
  padding: 0 10px;
  pointer-events: none;
  transition: all .35s ease;
  background: #fff
}

.contct-form .form-group .form-control {
  border-radius: 0;
  border: 1px solid rgba(28, 29, 36, .5);
  height: 40px;
  box-shadow: none !important;
  font-size: 15px;
  color: #1c1d24
}

.contct-form .form-group .form-control:focus {
  border-color: #dc965a !important
}

.contct-form .form-group .form-control::-webkit-input-placeholder {
  color: transparent
}

.contct-form .form-group .form-control:-ms-input-placeholder {
  color: transparent
}

.contct-form .form-group .form-control::placeholder {
  color: transparent
}

.contct-form .form-group .form-control:not(:-ms-input-placeholder)~.form-label {
  top: -10px;
  font-size: 9px;
  border-radius: 30px;
  border: 1px solid rgba(28, 29, 36, .5);
  left: 10px
}

.contct-form .form-group .form-control:not(:placeholder-shown)~.form-label {
  top: -10px;
  font-size: 9px;
  border-radius: 30px;
  border: 1px solid rgba(28, 29, 36, .5);
  left: 10px
}

.contct-form .form-group textarea.form-control {
  height: inherit
}

.contact-info .media .icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: rgba(220, 150, 90, .1);
  color: #dc965a;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 24px
}

.contact-info .media .media-body {
  padding-left: 15px;
  color: #1c1d24
}

.contact-info .media+.media {
  margin-top: 20px
}

.video-background {
  display:none;
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1 !important
}

.video-background video {
  width: 100%;
  height: 100%;
  object-fit: cover
}

.inner-content-static .social-icons {
  padding-top: 10px;
  position: static
}

.inner-content-static .scroll-bottom {
  left: 49%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  display: block
}

@media (max-width:767px) {
  .inner-content-static .scroll-bottom {
    left: 48%
  }
}

.inner-content-static .scroll-bottom a {
  font-size: 25px
}

.home-banner h1 span {
  color: #dc965a
}

.frame-layout__particles {
  width: 100%;
  height: 100%;
  position: absolute !important;
  z-index: 1 !important;
  top: 0;
  left: 0
}

.frame-layout__particles #tsparticles {
  height: 100%
}

.slider-three-animation.home-banner .hb-typo h1 {
  margin-bottom: 12px
}

.slider-three-animation p {
  font-size: 18px;
  color: #fff;
  margin-top: 20px
}

.slider-three-animation .hb-typo h2,
.video-banner-dark .hb-typo h2 {
  font-weight: 700
}

.slider-three-animation .loop-text,
.video-banner-dark .loop-text {
  color: #dc965a
}

.go-to-next {
  position: absolute !important;
  bottom: 30px;
  left: 0;
  right: 0;
  width: 35px;
  margin: auto;
  z-index: 1;
  text-align: center
}

.go-to-next a {
  width: 35px;
  height: 35px;
  position: relative;
  display: inline-block;
  background: #d9832e;
  border-radius: 50%;
  animation: down 1.3s linear infinite;
  -webkit-animation: down 1.3s linear infinite
}

.go-to-next a span {
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
  width: 10px;
  height: 10px;
  position: absolute;
  top: -6px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg)
}

@-webkit-keyframes down {
  0% {
    top: 5px;
    opacity: 0
  }

  30% {
    top: 15px;
    opacity: 1
  }

  to {
    top: 25px;
    opacity: 0
  }
}

@keyframes down {
  0% {
    top: 5px;
    opacity: 0
  }

  30% {
    top: 15px;
    opacity: 1
  }

  to {
    top: 25px;
    opacity: 0
  }
}

.sidebar-logo {
  display: none
}

@media screen and (max-width:991px) {
  .sidebar-logo {
    cursor: pointer !important;
    display: block;
    width: 75px;
    padding-top: 30px;
    margin-left: 30px;
    position: relative;
    z-index: 5
  }
}

.about-gallery a {
  display: block;
  cursor: zoom-in !important
}

.display-3 {
  font-size: 4rem;
  font-weight: 700
}

@media screen and (max-width:1199px) {
  .display-3 {
    font-size: 3rem
  }
}

.white-color {
  color: #fff
}

.h4 {
  font-size: 1.5rem
}

.error-page-wrapper {
  background: #1a1a1a;
  padding: 80px 0;
  height: calc(100vh - 71px) !important;
  display: flex;
  align-items: center
}

.error-page-wrapper .inner {
  padding-top: 35px
}

.error-page-wrapper .inner img {
  max-width: 80%
}

@media screen and (max-width:1919px) {
  .error-page-wrapper .inner img {
    max-width: 330px
  }
}

.error-page-wrapper .inner .display-3 {
  font-size: 40px
}

.error-page-wrapper .inner .h4 {
  font-size: 19px;
  margin: 15px 0;
  line-height: 1.4
}

.error-page-wrapper .h4 {
  color: #cbcbcb
}

.home-dark {
  background: #131416;
  color: #878e99;
  position: relative;
  z-index: 1
}

.home-dark .about-me .media label {
  color: #efefef
}

.home-dark .home-banner .bottom-style svg,
.home-dark .home-banner .top-style svg {
  color: #483d8b
}

.home-dark .home-banner .bottom-style.dark-shape svg,
.home-dark .home-banner .top-style.dark-shape svg {
  color: #101010
}

.home-dark .navbar-toggler,
.home-dark .toggler-menu {
  background: transparent
}

.home-dark .author-signeture {
  -webkit-filter: invert(.55);
  filter: invert(.55)
}

.home-dark .blog-post .blog-info,
.home-dark .feature-box-01,
.home-dark .feature-box-02 {
  background: #131416
}

.home-dark .feature-box-02 h6 {
  color: hsla(0, 0%, 100%, .65)
}

.home-dark .feature-box-02 h5 {
  color: #fff
}

.home-dark .main-header.fixed-header {
  background: #1c1d24
}

.home-dark .fixed-header.main-header {
  border-color: #2a2a2a
}

.home-dark .fixed-header.main-header .navbar-nav>li.current a {
  color: #dc965a
}

@media screen and (max-width:991px) {
  .home-dark .fixed-header .header-transparent {
    background: #1c1d24
  }
}

.home-dark .main-header.fixed-header .navbar-brand {
  -webkit-filter: invert(0);
  filter: invert(0)
}

@media (min-width:992px) {
  .home-dark .main-header {
    border-bottom-color: hsla(0, 0%, 100%, .15)
  }

  .home-dark .main-header .navbar-nav>li {
    border-right-color: hsla(0, 0%, 100%, .15);
    border-left-color: hsla(0, 0%, 100%, .15)
  }
}

.home-dark .fixed-header .navbar-nav>li {
  border-right-color: #2a2a2a;
  border-left-color: #2a2a2a
}

.home-dark .about-section .typo-box h3,
.home-dark .about-section .typo-box h5,
.home-dark .blog-post .blog-info h6,
.home-dark .contct-form .form-group .form-control,
.home-dark .contct-form .form-group .form-label,
.home-dark .feature-box-01 h5,
.home-dark .fixed-header .navbar-brand,
.home-dark .fixed-header .navbar-nav>li>a,
.home-dark .portfolio-box-01 h5 a,
.home-dark .portfolio-filter-01 .filter li.react-tabs__tab,
.home-dark .section-title h3,
.home-dark .skill-lt h6,
.home-dark .sm-title h4,
.home-dark .testmonial-box .testmonial-info h6 {
  color: #fff
}

.home-dark .blog-post .blog-info h6:hover,
.home-dark .portfolio-box-01:hover h5 a {
  color: #dc965a
}

.home-dark .contact-info .media .media-body {
  color: #878e99
}

.home-dark .contct-form,
.home-dark .gray-bg {
  background: #1c1d24
}

.home-dark .contct-form .form-group .form-control,
.home-dark .contct-form .form-group .form-label,
.home-dark .portfolio-box-01 {
  background: #131416
}

.home-dark .contct-form .form-group .form-control {
  border-color: #1a1a1a
}

.home-dark .portfolio-img img {
  opacity: .8
}

.home-dark .after-left-section:after {
  background: #1a1a1a
}

.home-dark .dark-img-box {
  background: #232323
}

@media (max-width:991px) {
  .home-dark .main-header .navbar-nav>li {
    border-top-color: #333;
    border-right: none
  }

  .home-dark .main-header .navbar-nav>li>a {
    color: #fff
  }

  .home-dark .main-header .navbar-nav {
    background: #1c1d24;
    position: relative
  }

  .home-dark .main-header .navbar-nav:before {
    content: "";
    position: absolute;
    background: #1c1d24;
    width: 100%;
    height: 60px;
    top: -60px
  }
}

.home-dark .blog-post .blog-info h6 a:hover {
  color: #dc965a
}

.home-dark.feature-section .feature-box-01 {
  background: #1c1d24
}

.preview-section {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #000
}

@media screen and (max-width:767px) {
  .preview-section {
    height: calc(100vh - 56px) !important
  }

  @supports (-webkit-appearance:none) {
    .os-android .preview-section {
      height: calc(100vh - 56px) !important;
      min-height: calc(100vh - 56px) !important
    }
  }
}

@media screen and (max-width:360px) {
  .preview-section {
    height: 100vh !important
  }
}

@media screen and (min-width:1920px) {
  .demo-section .container {
    max-width: 1440px;
    padding-bottom: 0
  }
}

.demo-section .title {
  margin-top: 25px
}

.demo-section .title h3 {
  margin: 0;
  color: #000;
  position: relative;
  z-index: 1;
  font-weight: 800;
  font-size: 20px;
  display: inline-block;
  vertical-align: top
}

@media screen and (max-width:767px) {
  .demo-section .title h3 {
    font-size: 20px
  }
}

.demo-section .title h3:after {
  content: "";
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: rgba(255, 147, 1, .5);
  position: absolute;
  top: -8px;
  left: -15px;
  z-index: -1
}

.demo-section .single-demo {
  transition: .3s;
  margin-top: 40px
}

.demo-section .single-demo a {
  cursor: pointer !important
}

.demo-section .single-demo:hover {
  -webkit-transform: translateY(-15px);
  transform: translateY(-15px)
}

.demo-section .portfolio-filter-01 {
  padding-top: 0
}

.demo-section .slick-dots {
  bottom: -40px
}

.feature-section .container-fluid {
  padding-left: 150px;
  padding-right: 150px
}

@media screen and (max-width:1499px) {
  .feature-section .container-fluid {
    padding-left: 15px;
    padding-right: 15px
  }
}

.feature-section .feature-box-01 {
  height: 100%;
  transition: .5s
}

.feature-section .feature-box-01:after {
  display: none
}

.feature-section .feature-box-01 h5 {
  font-size: 22px;
  margin-bottom: 10px
}

.feature-section .feature-box-01 .icon img {
  width: 55px;
  height: 55px;
  margin-bottom: 30px;
  background: hsla(0, 0%, 100%, .1);
  padding: 10px;
  border-radius: 50%;
  transition: .5s
}

.feature-section .feature-box-01:hover {
  background: #222
}

.feature-section .feature-box-01:hover .icon img {
  background: #fff
}

.title-wrapper .title {
  font-size: 40px;
  font-weight: 800;
  color: #fff;
  margin-top: 0;
  margin-bottom: 40px
}

@media screen and (max-width:1499px) {
  .title-wrapper .title {
    font-size: 30px
  }
}

.title-wrapper .title .theme-color {
  color: #dc965a
}

.title-wrapper .tag {
  background: #dc965a;
  color: #fff
}

.tag {
  display: inline-block;
  background: #1c1d24;
  padding: 0 10px;
  color: #dc965a;
  border-radius: 4px;
  margin-bottom: 10px
}

.purchase-block-inner h3 {
  max-width: 600px;
  font-weight: 800;
  margin: 0 auto;
  font-size: 35px;
  display: block;
  padding: 10px 0 30px;
  letter-spacing: 1px;
  line-height: 45px;
  color: #000
}

@media screen and (max-width:767px) {
  .purchase-block-inner h3 {
    font-size: 30px;
    line-height: 40px
  }
}

.purchase-block-inner .theme-color {
  color: #dc965a
}

.purchase-block-inner .dark-color {
  font-size: 17px;
  font-weight: 500;
  display: inline-block
}

@media screen and (min-width:1200px) {
  .purchase-block-inner .px-btn {
    font-size: 18px;
    line-height: 55px;
    letter-spacing: 1px;
    padding: 0 35px
  }
}

.preview-inner .filter {
  flex-wrap: wrap;
  background: #fff5ec;
  max-width: 408px;
  border-radius: 10px;
  padding: 10px 40px;
  margin: auto auto 23px
}

.preview-header .px-btn {
  line-height: 52px;
  text-transform: uppercase
}

@media screen and (max-width:767px) {
  .preview-header .px-btn {
    line-height: 42px;
    margin-bottom: 5px
  }
}

.preview-header h3 {
  font-size: 35px;
  margin: 30px 0 15px;
  color: #fff;
  font-weight: 600
}

@media screen and (max-width:767px) {
  .preview-header h3 {
    font-size: 20px;
    margin-top: 20px;
    line-height: 1.3
  }
}

.preview-header .theme-color {
  font-size: 40px;
  font-weight: 800;
  line-height: 1
}

.preview-header .lead {
  font-weight: 300;
  font-size: 18px;
  margin-bottom: 25px;
  color: hsla(0, 0%, 100%, .9)
}

@media screen and (max-width:767px) {
  .preview-header .navbar-brand {
    height: 32px
  }
}

.footer,
.purchase-section,
section.testimonial {
  overflow: hidden
}

.demo-toggler {
  width: 25px;
  position: fixed;
  z-index: 99;
  right: 0;
  top: calc(50% - 65px)
}

.demo-toggler .toggler-btn {
  border-radius: 4px 0 0 4px;
  background: #dc965a;
  border: 2px solid #dc965a;
  color: #fff;
  box-shadow: 0 0 10px hsla(0, 0%, 100%, .1);
  transition: .3s;
  position: relative;
  z-index: 2;
  font-size: 14px;
  width: 100%;
  min-width: 100%;
  padding: 10px 0 8px;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer
}

.demo-toggler .toggler-btn:hover {
  background: #fff5ec
}

.demo-toggler .toggler-btn:hover a {
  color: #000
}

.demo-toggler .toggler-btn a {
  color: #fff
}

.demo-toggler .inner-text {
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  -webkit-text-orientation: upright;
  text-orientation: upright
}

.inner-pages-wrapper .single-demo {
  display: block;
  background: #333;
  padding: 5px;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  margin-top: 30px;
  transition: .4s
}

.inner-pages-wrapper .single-demo:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, .1);
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: .5s
}

@media screen and (max-width:767px) {
  .inner-pages-wrapper .single-demo:before {
    background: transparent
  }
}

.inner-pages-wrapper .single-demo img {
  width: 100%
}

.inner-pages-wrapper .single-demo:hover {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px)
}

.inner-pages-wrapper .single-demo:hover:before {
  background: transparent;
  opacity: 1;
  visibility: visible
}

.inner-pages-wrapper .inner-title {
  color: #fff;
  text-align: center;
  position: relative;
  background: #1c1d24;
  padding: 10px;
  margin: 0;
  font-weight: 600
}

.section.inner-pages-wrapper {
  background: #000
}

.preview-sticker {
  width: 134px;
  height: 134px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1c1d24;
  color: #fff;
  font-weight: 400 !important;
  font-size: 22px;
  flex-direction: column;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 20px;
  z-index: 99;
  line-height: 1.1
}

@media screen and (max-width:767px) {
  .preview-sticker {
    width: 80px;
    height: 80px;
    left: 15px;
    font-size: 20px
  }
}

.preview-sticker .price {
  font-weight: 800;
  font-size: 35px;
  margin-top: 5px
}

@media screen and (max-width:767px) {
  .preview-sticker .price {
    font-size: 25px
  }
}

.preview-sticker.price-sticker {
  background: #ca1d1d;
  color: #fff;
  top: auto;
  left: auto;
  right: 20px;
  bottom: 20px
}

@media screen and (max-width:767px) {
  .preview-sticker.price-sticker {
    right: 15px;
    bottom: 15px
  }
}

.preview-sticker.react-sticker {
  border-radius: 0 0 80px 80px
}

@media screen and (max-width:767px) {
  .preview-sticker.react-sticker img {
    max-width: 55%
  }
}

@media screen and (max-width:767px) {
  .preview-section {
    padding: 0 5px
  }

  p br {
    display: none
  }
}

.rotate-both-side {
  -webkit-animation: rotate-both-side 4s infinite both;
  animation: rotate-both-side 4s infinite both
}

@-webkit-keyframes rotate-both-side {
  0% {
    -webkit-transform: rotate(-5deg);
    transform: rotate(-5deg)
  }

  50% {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg)
  }

  to {
    -webkit-transform: rotate(-5deg);
    transform: rotate(-5deg)
  }
}

@keyframes rotate-both-side {
  0% {
    -webkit-transform: rotate(-5deg);
    transform: rotate(-5deg)
  }

  50% {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg)
  }

  to {
    -webkit-transform: rotate(-5deg);
    transform: rotate(-5deg)
  }
}

.slide-both-side {
  -webkit-animation: slide-both-side 4s infinite both;
  animation: slide-both-side 4s infinite both
}

@-webkit-keyframes slide-both-side {
  0% {
    -webkit-transform: translate(-5px);
    transform: translateY(-5px)
  }

  50% {
    -webkit-transform: translateY(5px);
    transform: translateY(5px)
  }

  to {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px)
  }
}

@keyframes slide-both-side {
  0% {
    -webkit-transform: translate(-5px);
    transform: translateY(-5px)
  }

  50% {
    -webkit-transform: translateY(5px);
    transform: translateY(5px)
  }

  to {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px)
  }
}

.custom-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, .75);
  z-index: 999
}

.custom-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border: 1px solid #ccc;
  background: #fff;
  overflow: visible;
  overflow: initial;
  border-radius: 4px;
  outline: none
}

.custom-modal .box_inner {
  height: 75vh;
  width: 1000px;
  overflow-y: auto
}

@media (max-width:1600px) {
  .custom-modal .box_inner {
    width: 1070px
  }
}

@media (max-width:1400px) {
  .custom-modal .box_inner {
    width: 900px
  }
}

@media (max-width:992px) {
  .custom-modal .box_inner {
    width: 700px
  }
}

@media (max-width:768px) {
  .custom-modal .box_inner {
    width: 560px
  }
}

@media (max-width:599px) {
  .custom-modal .box_inner {
    width: 460px
  }
}

@media (max-width:490px) {
  .custom-modal .box_inner {
    width: 360px
  }
}

@media (max-width:420px) {
  .custom-modal .box_inner {
    width: 320px
  }
}

.custom-modal .scrollable {
  padding: 50px
}

@media (max-width:767px) {
  .custom-modal .scrollable {
    padding: 30px 15px
  }
}

.custom-modal .blog-grid {
  cursor: default
}

.custom-modal .blog-grid .blog-img img,
.custom-modal .blog-grid:hover .blog-img img {
  -webkit-transform: scale(1);
  transform: scale(1)
}

.custom-modal .blog-grid:hover .blog-img img {
  filter: grayscale(0);
  -webkit-filter: grayscale(0)
}

@media (max-width:767px) {
  .custom-modal .article-comment {
    padding: 30px 15px
  }
}

.ReactModal__Overlay {
  opacity: 0;
  transition: all .3s ease
}

.ReactModal__Overlay--after-open {
  opacity: 1
}

.ReactModal__Overlay--before-close {
  opacity: 0
}

.close-modal {
  background: transparent;
  border: none;
  display: flex;
  width: 45px;
  height: 45px;
  position: absolute;
  z-index: 4;
  right: -50px;
  top: -32px
}

@media screen and (max-width:1199px) {
  .close-modal {
    right: 0;
    top: -60px
  }
}

@media screen and (max-width:575px) {
  .close-modal {
    right: -10px;
    top: -37px
  }
}

.close-modal img {
  width: 45px;
  height: 45px;
  cursor: pointer;
  position: absolute;
  -webkit-filter: invert(1);
  filter: invert(1);
  transition: .3s
}

@media screen and (max-width:575px) {
  .close-modal img {
    width: 30px;
    height: 30px
  }
}

.close-modal:hover img {
  -webkit-filter: invert(.8);
  filter: invert(.8)
}

