

/* Text Size */
.text-0 {
  font-size: 11px !important;
  font-size: 0.6875rem !important;
}

.text-1 {
  font-size: 12px !important;
  font-size: 0.75rem !important;
}

.text-2 {
  font-size: 14px !important;
  font-size: 0.875rem !important;
}

.text-3 {
  font-size: 16px !important;
  font-size: 1rem !important;
}

.text-4 {
  font-size: 18px !important;
  font-size: 1.125rem !important;
}

.text-5 {
  font-size: 21px !important;
  font-size: 1.3125rem !important;
}

.text-6 {
  font-size: 24px !important;
  font-size: 1.50rem !important;
}

.text-7 {
  font-size: 28px !important;
  font-size: 1.75rem !important;
}

.text-8 {
  font-size: 32px !important;
  font-size: 2rem !important;
}

.text-9 {
  font-size: 36px !important;
  font-size: 2.25rem !important;
}

.text-10 {
  font-size: 40px !important;
  font-size: 2.50rem !important;
}

.text-11 {
  font-size: calc(1.4rem + 1.8vw) !important;
}

.padding-left-24{ padding-left: 24px; }
  
  .font-weight-600 { font-weight:600;  }

  .list-style-1{ padding:0; }
  .list-style-1 > li { list-style-type: none; border-bottom: 1px solid #eaeaea; padding:12px 0 }

  .round-15{
    border-radius: 50%;
  }
  
  .main-header .logo{
    padding-left:12px;
    font-size: 2.5rem;
    color: white;
    font-weight: 600;
  }

@media (min-width: 991px){  
  .main-header.fixed-header .logo{
    color: black;
  }
}

.intro-photo{
    background-size: contain;
    background-color: black;
    background-position: 10% 98px;
}

.bootstrap-modal-item{
  display: flex; 
  justify-content:center;
  align-items:flex-start;
}

.modal-content{
  color: #1c1d24;
}

@media (max-width: 1100px){
  .flex-column-conditional{
    flex-direction: column;
  }    
  .bootstrap-modal-item 
  > .bootstrap-modal-item-text {
    padding-top:24px;    
  }
}

@media (min-width:1100px){
  .bootstrap-modal-item 
  > .bootstrap-modal-item-text {
    width: 50%;
  }
}

.spinner{
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: #000000aa;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.hidden{display:none;}

.round-close{
    border-radius: 12px;
  }
  .btn-line-height{
    line-height: 8px;
  }
